import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { ConfigContext } from '../../App/App';
import { AppContext } from '../../App/AppContext';
import styles from './Footer.module.css';
import FooterActions from './FooterActions/FooterActions';
function Footer() {
  const config = React.useContext(ConfigContext);
  const { state } = useContext(AppContext);
  return (
    <Box className={`${styles.sharedFooter} ${state.shrunk ? styles.sharedFooterShrunk : ""}`} sx={{ margin: `${config.PaddingNumber}px`, width: `calc(100% - ${config.PaddingNumber * 2}px)`, background: 'none', boxShadow: 'none' }}>
      <FooterActions />
    </Box>
  );
}
export default Footer; 