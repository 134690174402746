import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import { ConfigContext } from '../../App/App';

function WrapperBox(props: { children: ReactNode, noFooter?: boolean }) {
    const config = React.useContext(ConfigContext);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto', zIndex: 1, padding: `${config.PaddingNumber}px`, gap: config.Gap, marginBottom: (props.noFooter ? "0px" : "90px") }}>
            {props.children}
        </Box>
    );
}
export default WrapperBox; 
