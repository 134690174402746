import { createTheme, darken, lighten, rgbToHex, Theme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { configInitial } from './config/configType';
import { useConfig } from './config/useConfig';

export function useTheme() {
  const config = useConfig()
  const defaultTheme = createTheme()
  const [loadedTheme, setLoadedTheme] = useState<Theme>(defaultTheme)
  const [themeLoaded, setThemeLoaded] = useState(false)

  useEffect(() => {
    if (config !== configInitial) {

      const loadTheme = () => {
        const primary = config.PrimaryColor
        const primaryLight = rgbToHex(lighten(primary, 0.3))
        const primaryDark = rgbToHex(darken(primary, 0.3))
        const secondary = config.SecondaryColor
        const secondaryLight = rgbToHex(lighten(secondary, 0.3))
        const secondaryDark = rgbToHex(darken(secondary, 0.3))
        const textInputBackground = config.TextInputBackground
        const boxShadow = config.BoxShadow
        const borderRadiusNumber = config.BorderRadiusNumber
        const borderRadius = `${borderRadiusNumber}px`
        const paddingNumber = config.PaddingNumber
        const padding = `${paddingNumber}px`
        const border = config.Border
        const transparentBackground = config.TransparentBackground
        const buttonBackground = config.ButtonBackground
        const muiFilledInputBackground = config.MuiFilledInputBackground
        const globalTheme = createTheme({
          palette: {
            primary: {
              main: primary,
              light: primaryLight,
              dark: primaryDark,
            },
            secondary: {
              main: secondary,
              light: secondaryLight,
              dark: secondaryDark,
            },
            error: {
              main: '#DC3545',
            },
            background: {
              default: transparentBackground,
            },
          },
          shape: {
            borderRadius: borderRadiusNumber,
          },
        })
        const mainTheme = createTheme({
          typography: {
            fontFamily: 'Inter',
            fontSize: 14,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
          },
          components: {
            MuiTypography: {
              variants: [
                {
                  props: { variant: 'h1' },
                  style: {
                    fontSize: '1rem',
                    color: globalTheme.palette.secondary.dark,
                    fontWeight: 'bold',
                  },
                },
              ],
            },
            MuiContainer: {
              styleOverrides: {
                root: {
                  background: transparentBackground,
                },
              },
            },
            MuiCard: {
              styleOverrides: {
                root: {
                  boxShadow: boxShadow,
                  overflow: "visible"
                },
              },
            },
            MuiPaper: {
              styleOverrides: {
                root: {
                  boxShadow: boxShadow,
                  background: 'white',
                  backgroundColor: 'white',
                  border: border,
                  overflow: "visible"
                },
              },
            },
            MuiButton: {
              styleOverrides: {
                root: {
                  boxShadow: boxShadow,
                  paddingTop: padding,
                  paddingBottom: padding,
                  background: buttonBackground,
                  backgroundColor: buttonBackground,
                },
              },
            },
            MuiInputBase: {
              styleOverrides: {
                root: {
                  background: `${textInputBackground} !important`,
                  backgroundColor: `${textInputBackground} !important`,
                  borderRadius: `${borderRadius} !important`,
                  boxShadow: boxShadow,
                  '&::before': {
                    borderBottom: '0px !important',
                  },
                },
              },
            },
            MuiBottomNavigation: {
              styleOverrides: {
                root: {
                  boxShadow: boxShadow,
                },
              },
            },
            MuiFilledInput: {
              styleOverrides: {
                root: {
                  background: muiFilledInputBackground,
                },
              }
            },
            MuiFormControl: {
              styleOverrides: {
                root: {
                  zIndex: '2',
                },
              },
            },
          },
          palette: globalTheme.palette,
          shape: globalTheme.shape,
        })

        const theme = createTheme({
          palette: globalTheme.palette,
          shape: globalTheme.shape,
          components: mainTheme.components,
          typography: mainTheme.typography
        })
        setLoadedTheme(theme)
        setThemeLoaded(true)
      }
      loadTheme()
    }
  }, [config])

  return { theme: loadedTheme, config, themeLoaded }
}
