import { Box, Card, Typography } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ChatIcon from 'remixicon-react/QuestionAnswerFillIcon';
import SearchIcon from 'remixicon-react/Search2FillIcon';
import { useSearch } from '../../../Utilities/Search';
import { useMessageSender } from '../../../Utilities/SendMessage';
import { ConfigContext } from '../../App/App';
import { AppContext } from '../../App/AppContext';
import { ChatMessage } from '../../Shared/API/ApiTypes';
import ActionButton, { ActionButtonProps } from '../../Shared/ActionButton/ActionButton';

function SplashActions() {
  const config = useContext(ConfigContext);
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const sendMessage = useMessageSender();
  const search = useSearch();


  const onSendMessage = async (message: ChatMessage) => {
    navigate("/Chat")
    sendMessage(message)
  }

  const onChatActionSubmit = (inputText: string) => {
    if (inputText !== "") {
      onSendMessage({ content: inputText, direction: 0, time: new Date(Date.now()) })
    }
  }

  const onSearchActionSubmit = async (inputText: string) => {
    if (inputText !== "") {
      await search(inputText);
    }
  }
  const chatActionProps: ActionButtonProps = {
    icon: <ChatIcon fontSize='large' />,
    primaryText: config.HomeAskAQuestionHeadline1,
    secondaryText: config.HomeAskAQuestionHeadline2,
    onActionEnter: onChatActionSubmit
  }
  const searchActionProps: ActionButtonProps = {
    icon: <SearchIcon fontSize='large' />,
    primaryText: config.HomeSearchHeadline1,
    secondaryText: config.HomeSearchHeadline2,
    onActionEnter: onSearchActionSubmit
  }
  return (
    <>
      <Card sx={{
        display: 'flex', flexDirection: 'column', width: '100%', padding: `${config.PaddingNumber}px`, py: 2
      }} >
        <Typography gutterBottom sx={{ fontSize: '1.1rem' }} component="div">
          {config.HomeHeadline2}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Typography paragraph={false} sx={{ fontSize: '1.3rem' }}>Nice to meet you{state.firstName !== '' ? ', ' : ''}</Typography>
          <Typography color="primary" sx={{ fontWeight: 'bold', fontSize: '1.3rem', ml: 1, mb: 1 }}>{state.firstName}</Typography>
        </Box>

        <Typography variant="body1" color="text.secondary">
          {config.HomeHeadline3}
        </Typography>
      </ Card>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: config.Gap, width: '100%' }}>
        <ActionButton actionButtonProps={chatActionProps} />
        <ActionButton actionButtonProps={searchActionProps} />
      </Box>
    </>
  );
}

export default SplashActions
