import { Card, CardActions, Collapse, IconButton, IconButtonProps, List, ListItem, styled, Typography } from '@mui/material';
import React from 'react';
import ExpandIcon from 'remixicon-react/ArrowDownSFillIcon';
import { ConfigContext } from '../../../../App/App';
import { ChatMessage } from '../../../../Shared/API/ApiTypes';
import MessageSending from '../../MessageSender/MessageSending';
import StructuredDataResultsList from '../StructuredData/StructuredDataResultsList';
import './Message.css';
import MessageContent from './MessageContent';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} sx={{ p: 0, display: 'flex', justifyContent: 'center' }} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Message(props: { message: ChatMessage }) {
  const config = React.useContext(ConfigContext);
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={'messageRow ' + (props.message.direction === 0 ? 'zero' : 'one')} >
      <Card elevation={2} className="chatMessage"
        sx={{
          p: 1,
          background: props.message.direction === 0 ? `${config.PrimaryColor}` : `white`,
          borderRadius: 2,
          color: props.message.direction === 0 ? 'white' : 'black',
          '&:hover': {
            backgroundColor: props.message.direction === 0 ? 'primary.light' : 'white'
          }
        }}
      >
        <div className="messageInfo">
          {(props.message.direction === 0 ? "You" : "Bot")}
          <div>
            |
          </div>
          <div>
            {new Date(props.message.time).toLocaleString([], { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
          </div>
        </div>
        <div>
          {props.message.temp && props.message.content === "" &&
            <MessageSending />
          }
          <MessageContent messageContent={props.message.content} />
        </div>
        {props.message.direction === 1 && props.message.relatedLinks &&
          <>
            <CardActions disableSpacing sx={{ ml: 0, p: 0, pt: 1 }}
              onClick={handleExpandClick}>
              <Typography color="GrayText" variant='body2' sx={{ fontWeight: 'bold' }}>Related Links</Typography>
              <ExpandMore
                expand={expanded}
                aria-expanded={expanded}
                aria-label="Related Links"
              >
                <ExpandIcon />
              </ExpandMore>
              {/* <Button variant="text" size="small" sx={{ background: 'white', backgroundColor: 'white !important', boxShadow: 'none', p: 0, m: 0 }}>Related Links</Button> */}
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <List sx={{ fontSize: '.8rem' }}>
                {props.message.relatedLinks.map((l, i) => {
                  return <ListItem key={i} sx={{ borderLeft: `3px solid ${config.SecondaryColor}`, marginBottom: 1, color: config.PrimaryColor }}><a href={l.url} target="_blank" rel="noreferrer">{l.title}</a></ListItem>
                })}

              </List>
            </Collapse>
          </>
        }
      </Card>
      {props.message.direction === 1 && props.message.cards &&
        <StructuredDataResultsList cards={props.message.cards} startCollapsed={true} />
      }
    </div>
  );
}
export default Message;