import { Box, Button } from '@mui/material';
import { ReactNode } from 'react';
import CloseIcon from 'remixicon-react/CloseFillIcon';
// import DragIcon from 'remixicon-react/DragMoveFillIcon';
import React from 'react';
import { ConfigContext, FrameAction } from '../../App/App';
import styles from './Header.module.css';

function Header(props: { left?: ReactNode, pageName: string }) {
  //const dragBtnRef = useRef<HTMLButtonElement>(null)
  //const dragClick = useDrag(dragBtnRef)
  const config = React.useContext(ConfigContext);
  // const { dispatch } = useContext(AppContext);

  // const shrinkWindow = (msg: FrameAction) => {
  //   sendMessageToParent(msg);
  //   dispatch({ type: "setShrunk", shrunk: msg.data })
  // }

  const sendMessageToParent = (message: FrameAction) => {
    window.parent.postMessage(message, "*");
  }

  return (
    <Box sx={{ p: 0 }} className={styles.headerWrapper}>
      <div className={styles.chatHeaderBack}>
        {/* {(window.history.state && window.history.state.idx > 0) &&
          <BackButton />
        } */}
        {/* {
          (!window.history.state || window.history.state.idx === 0) && !props.left &&
          <Box className={styles.headerLogo} sx={{ display: 'flex', alignItems: 'center' }}><img src={config.SmallHeaderLogoUrl} alt="Logo" /></Box>
        } */}
        {props.left &&
          props.left
        }
      </div>
      {/* <div className={styles.headerPageTitle}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '1rem', color: 'white', textShadow: `-2px -2px 30px ${config.PrimaryColor}, 2px -2px 30px ${config.PrimaryColor},-2px 2px 30px ${config.PrimaryColor},2px 2px 30px ${config.PrimaryColor};` }}> {props.pageName}</Typography>
      </div> */}
      <div className={styles.headerActionsWrapper}>
        <div className={styles.headerActionsBtns}>
          {/* <Button
            id="appWrapperMinimizeBtn"
            className={styles.landscapeBtn}
            variant="text"
            sx={{ fontWeight: 'bold', p: .5, minWidth: "10px", border: border }}
            ref={dragBtnRef}
          >
            <ArrowUpIcon fontSize='small' />
          </Button> */}
          {/* <Button
            id="appWrapperMoveBtn"
            variant="text"
            className={styles.landscapeBtn}
            sx={{ fontWeight: 'bold', p: .5, minWidth: "10px", border: config.Border }}
          >
            <DragIcon fontSize='small' />
          </Button> */}
          {/* <Button
            id="appWrapperMinimizeBtn"
            className={(state.shrunk ? styles.landscapeBtn : styles.portraitBtn)}
            variant="text"
            sx={{ fontWeight: 'bold', p: .5, minWidth: "10px", border: config.Border }}
            onClick={() => shrinkWindow({ isEdulla: true, action: 'shrinkWidget', data: !state.shrunk })}
          >
            {(state.shrunk ? <ArrowUpIcon fontSize='small' /> : <ArrowDownIcon fontSize='small' />)}
          </Button> */}
          {config.TypeOfBot !== "catalog" &&
            <Button
              id="appWrapperCloseBtn"
              variant="text"
              sx={{ fontWeight: 'bold', p: .5, minWidth: "10px", border: config.Border }}
              onClick={() => sendMessageToParent({ isEdulla: true, action: 'closeWidget', data: false })}
            >
              <CloseIcon fontSize='small' />
            </Button>}
        </div>
      </div>
    </Box>
  );
}
export default Header; 