import { useContext } from 'react';
import api from "../API/api";
import { ConfigContext } from "../Components/App/App";
import { AppContext } from "../Components/App/AppContext";

export function useSearch() {
  const { state, dispatch } = useContext(AppContext);
  const config = useContext(ConfigContext);

  const searchStandard = async (inputText?: string) => {
    let newSearchQuery = inputText ?? state.searchResults.searchQuery;
    dispatch({ type: "setSearchResults", searching: true, searchResults: [], structuredResults: [], showSearchResults: true, searchQuery: newSearchQuery, showLinks: state.searchResults.showLinks, showSearch: true })
    let result = await api.search.start({ query: newSearchQuery, index: config.IndexName, sessionid: state.sessionid })
    dispatch({ type: "setSearchResults", searching: false, searchResults: result.links, showSearchResults: true, searchQuery: state.searchResults.searchQuery, showLinks: true, showSearch: true, structuredResults: [] })
    dispatch({ type: "setSearchQuery", searchQuery: newSearchQuery })
  }

  const searchCatalog = async (inputText?: string) => {
    let newSearchQuery = inputText ?? state.searchResults.searchQuery;
    dispatch({ type: "setSearchResults", searching: true, searchResults: [], structuredResults: [], showSearchResults: true, searchQuery: newSearchQuery, showLinks: state.searchResults.showLinks, showSearch: true })
    let result = await api.searchCatalog.start({ question: newSearchQuery, index: config.IndexName, sessionid: state.sessionid, personal_details: {}, context: [] })
    // dispatch({ type: "setPersonalDetails", personalDetails: result.personal_details ?? {} })
    dispatch({ type: "setFormDetails", formDetails: result.form_details ?? {} })
    // dispatch({ type: "setFilteredFormData", filteredFormData: result.filtered_items ?? {} })

    dispatch({ type: "setSearchResults", searching: false, searchResults: [], structuredResults: result.filtered_items, showSearchResults: true, searchQuery: state.searchResults.searchQuery, showLinks: true, showSearch: true })

    if (!(state.filteredFormData?.length > 0)) {
      dispatch({ type: "setFilteredFormData", filteredFormData: result.filtered_items ?? {} })
    }

    // dispatch({ type: "setSearchResults", searching: false, searchResults: [], structuredResults: searchResults, showSearchResults: false, searchQuery: state.searchResults.searchQuery, showLinks: false, showSearch: false })
    // dispatch({ type: "setSearchResults", searching: false, searchResults: result.links, showSearchResults: true, searchQuery: state.searchResults.searchQuery, showLinks: true, showSearch: true })
    dispatch({ type: "setSearchQuery", searchQuery: newSearchQuery })
  }

  const search = async (inputText?: string) => {
    if (config.TypeOfBot?.includes("catalog")) {
      await searchCatalog(inputText)
    }
    else {
      await searchStandard(inputText);
    }
  }
  return search;
}
