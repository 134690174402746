
import styles from './MessageSending.module.css';
function MessageSending() {


  return (
    <div className={styles.messageSendingWrapper}>
      <svg viewBox="0 0 100 100" className={styles.messageSendingCircle} xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="50" fill="gray" />
      </svg>
      <svg viewBox="0 0 100 100" className={styles.messageSendingCircle} xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="50" fill="gray" />
      </svg>
      <svg viewBox="0 0 100 100" className={styles.messageSendingCircle} xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="50" fill="gray" />
      </svg>
    </div>
  );
}
export default MessageSending;