import { BottomNavigationAction } from "@mui/material";
import React, { ReactNode } from "react";
import { ConfigContext } from "../../../App/App";
import styles from './FooterActions.module.css';

function FooterNavigationAction(props: { navigate: (route: string) => void, route: string, label: string, icon: ReactNode, flipColors: boolean }) {
    const matchesRoute = window.location.pathname.toLowerCase() === props.route.toLowerCase()
    const config = React.useContext(ConfigContext);
    return (
        <BottomNavigationAction
            label={props.label}
            icon={props.icon}
            className={styles.chatFooterActionBtn}
            onClick={() => props.navigate(props.route)}
            sx={{
                borderRadius: `${config.BorderRadiusNumber}px`,
                my: 0,
                p: 0,
                "& svg, .MuiBottomNavigationAction-label": {
                    color: (matchesRoute ? "primary.main" : "default"),
                    fontWeight: (matchesRoute ? "bold" : "normal"),
                    opacity: 1
                },
                "& svg path": {
                    fill: (matchesRoute ? "url(#gradientActive) !important" : "url(#gradient)"),
                }
            }}
        />
    )
}

export default FooterNavigationAction