import { Collapse, List, ListItem, Paper, Typography } from '@mui/material';
import { useContext } from 'react';
import { ConfigContext } from '../../../App/App';
import { AppContext } from '../../../App/AppContext';
import StructuredDataResultsList from '../../../Chat/Body/Messages/StructuredData/StructuredDataResultsList';
import styles from './SearchResults.module.css';
function SearchResults(props: { showSearchResults: boolean }) {
  const config = useContext(ConfigContext);
  const { state } = useContext(AppContext);
  return (
    <Collapse in={props.showSearchResults} sx={{
      overflowY: "hidden",
      height: '0px',
      padding: '0px',
      marginTop: '-1px'
    }}
      classes={{ root: styles.searchResultsCollapse }}>
      <Paper sx={{
        borderRadius: '0px', background: 'white',
        overflowY: "auto",
        maxHeight: '100%',
        width: '100%',
        borderLeft: `2px solid ${config.TertiaryColor}`,
        borderRight: `2px solid ${config.TertiaryColor}`,
        borderTop: '0px',

        borderBottom: '0px'
      }}
        className={styles.searchResults}>
        <Typography variant='body2' sx={{ p: 2 }}>{(state.searchResults.searching ? "Searching " : "Results")} for <b>"{state.searchResults.searchQuery}"</b></Typography>

        <List sx={{ p: 2, fontSize: '.8rem' }}>
          {state.searchResults?.searchResults?.length > 0 &&
            state.searchResults.searchResults.map((link, i) => {
              return <ListItem
                sx={{
                  borderLeft: `3px solid ${config.SecondaryColor}`,
                  marginBottom: 1,
                  color: config.PrimaryColor
                }}
                key={i}>
                <a href={link.url} target="_blank" rel="noreferrer">{link.title}</a>
              </ListItem>
            })}
          {state.searchResults?.structuredResults?.length > 0 &&
            <StructuredDataResultsList cards={state.searchResults.structuredResults} startCollapsed={false} />
          }

        </List>

      </Paper >
    </Collapse>
  );
}
export default SearchResults; 