import { ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import React from 'react';
import LinkIcon from 'remixicon-react/LinksFillIcon';
import { ConfigContext } from '../../../App/App';
function Result() {
    const config = React.useContext(ConfigContext);
    return (
        <ListItem sx={{ mx: 0, px: 0 }}>
            <ListItemAvatar sx={{ minWidth: '45px' }}>

                <LinkIcon fontSize="large" />
            </ListItemAvatar>
            <ListItemText
                disableTypography
                primary={<Typography variant="body2" style={{ color: config.PrimaryColor, fontSize: '.9rem' }}>Admissions and Records</Typography>}
                secondary="https://admissions.edu/apply" />
        </ListItem>
    );
}
export default Result;

