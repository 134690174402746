import { createContext, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useConfig } from '../../config/useConfig';
import { usePersistReducer } from "../../Utilities/usePersistReducer";
import { AppAction, appReducer, AppState, getStorageIdentifier, initialState } from "./appReducer";

export const AppContext = createContext<{
    state: AppState;
    dispatch: React.Dispatch<AppAction>;
}>({
    state: initialState,
    dispatch: () => null
});

export const AppProvider = (props: { children: ReactNode }) => {
    const config = useConfig()
    initialState.messages[0]["content"] = config?.WelcomeMessage ?? "Hello, how can I help you today?";
    const [appState, dispatch] = usePersistReducer(initialState, getStorageIdentifier(), appReducer);
    const { pathname } = useLocation();
    useEffect(() => {
        if (pathname !== "/index.html")
            dispatch({ type: "setLastLocation", location: pathname })
    }, [pathname, dispatch])
    return (
        <AppContext.Provider value={{ state: appState, dispatch }}>
            {props.children}
        </AppContext.Provider>
    );
};