import { Badge, Button, SwipeableDrawer } from '@mui/material';
import React, { useEffect } from 'react';
import ContactIcon from 'remixicon-react/ContactsBookFillIcon';
import { ConfigContext } from '../../../App/App';
import { AppContext } from '../../../App/AppContext';
import './HeaderActions.css';
import HeaderDepartmentModal from './HeaderDepartmentModal';

function HeaderActions() {
  const [showDepartmentInfo, setShowDepartmentInfo] = React.useState(false);
  const config = React.useContext(ConfigContext);
  const { state, dispatch } = React.useContext(AppContext);

  useEffect(() => {
    if (state.contactInformation && !state.contactInformation.viewed) {
      setShowDepartmentInfo(true)
    }
  }, [state.contactInformation])

  const toggleDrawer =
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      if (showDepartmentInfo) {
        dispatch({ type: "setContactInformationViewed", viewed: true })
      }
      setShowDepartmentInfo(!showDepartmentInfo);
    };
  return (
    <>
      {state.contactInformation &&
        <>
          <Button className={"showDepartmentInfoBtn " + (showDepartmentInfo ? "showExpand" : "")} onClick={toggleDrawer} variant="text" sx={{ fontWeight: 'bold', p: .5, minWidth: "10px", border: config.Border }} >
            <Badge color="error" variant="dot" invisible={state.contactInformation?.viewed}>
              <ContactIcon fontSize='small' />
            </Badge>
          </Button>
          <SwipeableDrawer
            anchor="top"
            open={showDepartmentInfo}
            className="departmentDrawer"
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
            PaperProps={{ style: { position: 'absolute', top: '5px', borderRadius: 10 } }}
            BackdropProps={{ style: { opacity: 0 } }}
            ModalProps={{
              container: document.getElementById('chatBody'),
              style: { position: 'absolute', width: '100%', height: 'auto', paddingTop: '100%', bottom: 'auto', top: 'auto', left: 'auto', right: 'auto' }
            }}
          >
            <HeaderDepartmentModal
              actionsParams={state.contactInformation}
              toggleDrawer={toggleDrawer}
            />
          </SwipeableDrawer>
        </>
      }
    </>
  );
}
export default HeaderActions;