import { Box } from '@mui/material';
import { useContext } from 'react';
import { ConfigContext } from '../App/App';
import Header from '../Shared/Header/Header';
import WrapperBox from '../Shared/WrapperBox/WrapperBox';
import SplashActions from './SplashActions/SplashActions';
import SplashHeader from './SplashHeader/SplashHeader';
function Splash() {
  const config = useContext(ConfigContext);
  return (
    <WrapperBox>
      <Header pageName='' />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: config.Gap, flexDirection: 'column', }}>
        <SplashHeader />
        <SplashActions />
      </Box>
    </WrapperBox>
  );
}
export default Splash; 