
import { Card } from '@mui/material';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { ConfigContext } from '../App/App';
import { AppContext } from '../App/AppContext';
import Header from '../Shared/Header/Header';
import HeroLogo from '../Shared/HeroLogo/HeroLogo';
import WrapperBox from '../Shared/WrapperBox/WrapperBox';
import Body from './Body/Body';
import DyanmicForm from './Body/DyanmicForm';

function Start() {
    const { state } = useContext(AppContext);
    const config = useContext(ConfigContext);
    return (
        <>
            {state.enteredInformation &&
                <Navigate to={state.lastLocation} />}
            {!state.enteredInformation &&
                <WrapperBox noFooter={true}>
                    <Header pageName='' />
                    <Card sx={{ p: 0, }}>
                        <HeroLogo />
                    </Card>
                    {config.CustomForm &&
                        <DyanmicForm />
                    }
                    {!config.CustomForm &&
                        <Body />}
                </WrapperBox>}
        </>
    );
}
export default Start; 