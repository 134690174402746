import { Box, Button, Card, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../../API/api';
import { ConfigContext } from '../../App/App';
import { AppContext } from '../../App/AppContext';
import styles from './DynamicForm.module.css';
import DynamicFormField from './DynamicFormFields/DynamicFormField';

export interface FormField {
    Label: string,
    Description: string,
    DataType: string,
    AllowedValues: string[],
    Required: boolean,
    FieldId: string,
    Validation: string,
    Children: FormField[],
    ChildrenShowOnValue: string
}

function DyanmicForm() {
    const config = useContext(ConfigContext);
    // const config = require('./oftc.json')
    const { dispatch } = useContext(AppContext);
    const navigate = useNavigate();
    const { state } = useContext(AppContext);
    const [submitting, setSubmitting] = useState(false);
    let [formValid, setFormValid] = useState(false)
    let form_details = config.EntranceForm["form_details"]
    let formFields = Object.entries(form_details.properties).map((e) => {
        let formField = {} as FormField;
        let firstLevel = (e[1] as any);
        let secondLevel = (e[1] as any).metadata[0];
        formField.Label = firstLevel.title
        formField.Description = firstLevel.description
        formField.DataType = secondLevel.data_type
        formField.Required = secondLevel.required
        formField.AllowedValues = secondLevel.allowed_values
        formField.Validation = secondLevel.validation
        formField.FieldId = secondLevel.field_id
        formField.Children = firstLevel?.children ? Object.entries(firstLevel.children).map((c: any) => {
            let formField2 = {} as FormField;
            let firstLevel2 = (c[1] as any);
            let secondLevel2 = (c[1] as any).metadata[0];
            formField2.Label = firstLevel2.title
            formField2.Description = firstLevel2.description
            formField2.DataType = secondLevel2.data_type
            formField2.Required = secondLevel2.required
            formField2.AllowedValues = secondLevel2.allowed_values
            formField2.Validation = secondLevel2.validation
            formField2.FieldId = secondLevel2.field_id
            return formField2
        }) : []
        formField.ChildrenShowOnValue = firstLevel["children_show_on_value"]
        return formField
    })

    const [fieldValidations, setFieldValidations] = useState<any[]>(formFields.flatMap((field) => {
        if (field?.Children?.length > 0) {
            let result: any[] = []
            let parent: any = {
                "FieldName": field.Label,
                "IsValid": field.Required ? false : true,
                "Error": "",
                "FieldId": field.FieldId
            }
            parent["Children"] = field.Children.map((c) => {
                return c.FieldId
            })
            result.push(parent)
            field.Children.forEach((c) => {
                result.push({
                    "FieldName": c.Label,
                    "IsValid": true,
                    "Error": "",
                    "FieldId": c.FieldId
                })
            })
            return result;
        }
        else {
            return [{
                "FieldName": field.Label,
                "IsValid": field.Required ? false : true,
                "Error": "",
                "FieldId": field.FieldId
            }];
        }
    }));

    const validateForm = () => {
        return fieldValidations.map((fieldValidation) => fieldValidation["IsValid"]).every(Boolean);
    }

    useEffect(() => {
        setFormValid(validateForm());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldValidations]);

    const validateField = (fieldName: string, isValid: boolean, error: string, fieldValue: any) => {
        let result = fieldValidations.map((fieldValidation) => {
            if (fieldValidation["FieldName"] === fieldName) {
                fieldValidation["IsValid"] = isValid
                fieldValidation["Error"] = error
                fieldValidation["FieldValue"] = fieldValue
            }
            return fieldValidation;
        })
        setFieldValidations(result)
    }

    const afterValidFormSubmission = () => {
        dispatch({ type: "setEnteredInformation", hasEnteredInformation: true });
        // let names = name.split(' ')
        // let firstName = names.length > 0 ? names[0] : ""
        // dispatch({ type: "setFirstName", firstName: firstName });
        navigate("/Home")
    }


    const submitForm = async () => {
        if (validateForm()) {
            setSubmitting(true);
            let request = {
                indexname: config.IndexName,
                sessionid: state.sessionid,
                objectid: config.EntranceForm["form_details"]["object_id"],
                fields: fieldValidations.map((fieldValidation) => {
                    return {
                        fieldid: fieldValidation["FieldId"],
                        fieldvalue: fieldValidation["FieldValue"]
                    };
                })
            }
            if (request) {
                await api.submitDyanmicInformation.submitDyanmicInformation
                    (request);
            }

            setSubmitting(false);
            afterValidFormSubmission();
        }
    }
    return (
        <Card sx={{ p: 3, flex: 1, justifyContent: "space-between", display: "flex", flexDirection: "column", position: 'relative' }}>
            {submitting && <Card sx={{ background: 'white', opacity: .5, width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, zIndex: 99, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <CircularProgress />
            </Card>}
            <form className='welcomeSubmit'>
                <Box sx={{ marginBottom: 2 }}>
                    <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold', mb: 0, pb: 0, textAlign: 'center' }}>Welcome!</Typography>
                    <Typography sx={{ textAlign: 'center' }}>Enter your information to get started.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }} >
                    {formFields.map((formField, i) => {
                        return <DynamicFormField key={i} form_field={formField} validateField={validateField} isChild={false} />
                    })}
                </Box>
                <Button variant="outlined" type="button" onClick={submitForm}
                    sx={{ width: '100%', background: formValid ? `${config.PrimaryColor} !important` : "#e8e8e8 !important", color: formValid ? `white !important` : "gray !important", marginTop: '15px', border: "0px !important" }} className={styles.submitDynamicFormButton} disabled={!formValid || submitting}>Get Started</Button>
            </form>
        </Card>
    );
}
export default DyanmicForm;