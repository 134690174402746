import { Box, Button, Card, FilledInput, FormControl, InputLabel, Link, MenuItem, Select, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../../API/api';
import { ConfigContext } from '../../App/App';
import { AppContext } from '../../App/AppContext';
import styles from './Body.module.css';

function Body() {
    const navigate = useNavigate();
    const config = React.useContext(ConfigContext);
    const formRequired = config.RequireForm ?? true
    const { state } = useContext(AppContext);
    // const navigate = useNavigate();
    // const { dispatch } = useContext(AppContext);
    // const enterInformation = () => {
    //     dispatch({ type: "setEnteredInformation", hasEnteredInformation: true });
    //     let names = name.split(' ')
    //     let firstName = names.length > 0 ? names[0] : ""
    //     dispatch({ type: "setFirstName", firstName: firstName });
    //     navigate("/Home")
    // }
    // const termsLabelText = "I Agree to Terms and Service"
    // const termsLabel = { inputProps: { 'aria-label': termsLabelText } };

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [type, setType] = useState(1);
    const [formValid, setFormValid] = useState(formRequired ? false : true);
    const [nameValid, setNameValid] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [typeValid, setTypeValid] = useState(true);
    const [emailErrors, setEmailErrors] = useState('');
    const [nameErrors, setNameErrors] = useState('');
    const [typeErrors, setTypeErrors] = useState('');
    // const [agreed, setAgreed] = useState(false);
    // const [agreedErrors, setAgreedErrors] = useState('');
    // const [agreedValid, setAgreedValid] = useState(true);

    const handleEmailInput = (e: any) => {
        setEmail(e.target.value);
        validateEmail(e.target.value);
    }

    const submitForm = async () => {
        console.log("Submitting")
        if (formValid) {
            setSubmitting(true);
            let firstName = name.includes(" ") ? name.split(" ")[0] : name;
            let lastName = name.includes(" ") ? name.split(" ")[1] : "";
            await api.submitInformation.submitInformation({ indexname: config.IndexName, firstname: firstName, lastname: lastName, email: email, visitortype: type === 1 ? "Prospective" : "Current", sessionid: state.sessionid })
            setSubmitting(false);
            afterValidFormSubmission();
        }
    }

    const { dispatch } = useContext(AppContext);
    const afterValidFormSubmission = () => {
        dispatch({ type: "setEnteredInformation", hasEnteredInformation: true });
        let names = name.split(' ')
        let firstName = names.length > 0 ? names[0] : ""
        dispatch({ type: "setFirstName", firstName: firstName });
        navigate("/Home")
    }

    useEffect(() => {
        setFormValid((nameValid && emailValid && typeValid) || !formRequired);
    }, [emailValid, nameValid, typeValid, formRequired])
    // }, [emailValid, nameValid, typeValid, agreedValid])

    const validateEmail = (fieldValue: any) => {
        let isEmailValid = fieldValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        setEmailErrors(isEmailValid ? '' : ' (Please Enter a Valid Email)');
        setEmailValid(isEmailValid ? true : false);
    }

    const handleNameInput = (e: any) => {
        setName(e.target.value);
        validateName(e.target.value);
    }

    const validateName = (fieldValue: any) => {
        let isNameValid = fieldValue.length > 0;
        setNameErrors(isNameValid ? '' : ' (Please Enter your Name)');
        setNameValid(isNameValid ? true : false);
    }

    const handleTypeInput = (e: any) => {
        setType(e.target.value);
        validateType(e.target.value);
    }

    const validateType = (fieldValue: any) => {
        let isTypeValid = fieldValue !== 0;
        setTypeErrors(isTypeValid ? '' : ' (Please Select a Choice)');
        setTypeValid(isTypeValid ? true : false);
    }

    // const handleAgreedInput = (e: any) => {
    //     setAgreed(e.target.checked);
    //     validateAgreed(e.target);
    // }

    // const validateAgreed = (fieldValue: any) => {
    //     let isAgreedValid = fieldValue.checked === true;
    //     setAgreedErrors(isAgreedValid ? '' : '(You Must Agree to the Terms to Continue)');
    //     setAgreedValid(isAgreedValid ? true : false);
    // }
    return (
        <>
            <Card sx={{ p: 0, flex: 1, justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                <form className='welcomeSubmit'>
                    <div className={styles.startBodyWrapper}>
                        <div>
                            <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold', mb: 0, pb: 0, textAlign: 'center' }}>Welcome!</Typography>
                            <Typography sx={{ textAlign: 'center' }}>Enter your information to get started.</Typography>
                        </div>
                        <FormControl variant="filled">
                            <InputLabel htmlFor="my-input" >Your Name{!nameValid && nameErrors}{!formRequired && " (Optional)"}</InputLabel>
                            <span></span>
                            <FilledInput id="my-input" aria-describedby="my-helper-text" disableUnderline required
                                value={name}
                                onChange={handleNameInput} />
                        </FormControl>
                        <FormControl variant="filled">
                            <InputLabel htmlFor="my-input">Email{!emailValid && emailErrors}{!formRequired && " (Optional)"}</InputLabel>
                            <FilledInput id="my-input" aria-describedby="my-helper-text" disableUnderline required
                                value={email}
                                onChange={handleEmailInput} />
                        </FormControl>
                        <FormControl variant="filled" fullWidth>
                            <InputLabel id="demo-simple-select-label" htmlFor="demo-simple-select" className={typeValid ? '' : 'text-danger'}>Which Best Describes You?{!typeValid && typeErrors}{!formRequired && " (Optional)"}</InputLabel>
                            <span>{typeErrors}</span>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={type}
                                label="Student Type"
                                onChange={handleTypeInput}

                            >
                                <MenuItem value={1}>Future Student</MenuItem>
                                <MenuItem value={2}>Current Student</MenuItem>
                            </Select>
                        </FormControl>
                        {/* <FormControl variant="filled" required>
                            <FormControlLabel label={termsLabelText} control={
                                <Checkbox {...termsLabel} value={agreed} onChange={handleAgreedInput} />} className={agreedValid ? '' : 'text-danger'} />
                            {!agreedValid && <span>{agreedErrors}</span>}
                        </FormControl> */}
                        <Button variant="outlined" sx={{ background: `${config.PrimaryColor} !important`, color: 'white !important', marginTop: '15px' }} type="button" onClick={submitForm} disabled={!formValid || submitting} >Get Started</Button>
                    </div>
                </form>
                <Box sx={{ padding: "10px", flexDirection: "column", display: "flex", justifyContent: "space-evenly", background: config.TextInputBackground, borderBottomRightRadius: `${config.BorderRadiusNumber}px`, borderBottomLeftRadius: `${config.BorderRadiusNumber}px` }}>

                    <Box sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", flexDirection: "row" }}>
                        <Typography variant='body2'>
                            <Link sx={{ color: config.PrimaryColor }} href="https://www.edulla.ai/legal/privacy" target="_blank" rel="noopener noreferrer" >Privacy Policy</Link>
                        </Typography>
                        <Typography variant='body2'>
                            |
                        </Typography>
                        <Typography variant='body2'>
                            <Link sx={{ color: config.PrimaryColor }} href="https://www.edulla.ai/legal/terms-of-use" target="_blank" rel="noopener noreferrer" >Terms of Use</Link>
                        </Typography></Box>
                    {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: '10px' }}>
                        <Typography variant='caption'>
                            Powered By
                        </Typography>
                        <img width="75" src="https://uploads-ssl.webflow.com/5f6a376527dcbac3d5c0e8a0/5f6e734850a6d035f9ae592f_edulla%20CI-SVG-02.svg" alt="Edulla Logo" />
                    </Box> */}
                </Box>
            </Card>
        </>
    );
}
export default Body;