import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ReactNode } from "react";

function ContactInfoListItem(props: { icon: ReactNode, text: string, type: string }) {

    const getLink = () => {
        switch (props.type) {
            case "phone":
                return `tel:${props.text}`;
            case "email":
                return `mailto:${props.text}`;
            case "address":
                return `https://www.google.com/maps/search/?api=1&query=${props.text}`;
            case "url":
                return props.text;
        }
        return ""
    }

    return (
        <ListItemButton component="a" target="_blank" rel="noopener noreferrer" href={getLink()} className="departmentInfoItem" sx={{ px: 0 }}>
            <ListItemIcon sx={{ minWidth: '0px', marginRight: '15px' }}>
                {props.icon}
            </ListItemIcon>
            <ListItemText secondary={props.text} />
        </ListItemButton>
    )
}

export default ContactInfoListItem