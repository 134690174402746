
import { FilledInput, FormControl, Grow, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormField } from '../DyanmicForm';

function DynamicFormField(props: { form_field: FormField, validateField: (fieldName: string, isValid: boolean, error: string, fieldValue: any) => any, isChild: boolean }) {
    let hasAllowedValues = props.form_field.AllowedValues?.length ?? -1 > 0;
    let [fieldValue, setFieldValue] = useState("");
    let [error, setError] = useState("")

    let showFieldChildren = props.form_field.Children?.length > 0 && props.form_field.ChildrenShowOnValue === fieldValue;

    let validateFormField = () => {
        let isValid = false;
        isValid = props.form_field?.AllowedValues?.length ?? -1 > 0 ? props.form_field.AllowedValues.indexOf(fieldValue) > -1 : true;
        let errorString = "";
        if (isValid && props.form_field.Validation) {
            isValid = fieldValue.match(props.form_field.Validation)?.length ?? -1 > 0 ? true : false;
            if (!isValid) {
                errorString = "Does not pass validation";
            }
        }
        else if (!isValid) {
            errorString = "Value not Allowed";
        }
        if (isValid) {
            errorString = ""
        }
        setError(errorString);
        props.validateField(props.form_field.Label, isValid, errorString, fieldValue)

    }

    useEffect(() => {
        if (fieldValue) {
            validateFormField()
        }
        else {
            setError("")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldValue]);

    let handleInput = (e: any) => {
        setFieldValue(e.target.value)
    }

    useEffect(() => {
        props.validateField(props.form_field.Label, props.form_field.Required ? false : true, "", fieldValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Grow in={true}>
                <FormControl variant="filled">
                    <InputLabel htmlFor={props.form_field.Label} required={props.form_field.Required} error={(error !== "")}>
                        {props.form_field.Label}
                        {error &&
                            <span > ({error})</span>}
                    </InputLabel>
                    {!hasAllowedValues &&
                        <FilledInput type={props.form_field.DataType} id={props.form_field.Label} disableUnderline required={props.form_field.Required}
                            onChange={handleInput} />
                    }
                    {hasAllowedValues &&
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fieldValue}
                            label="Student Type"
                            onChange={handleInput}

                        >
                            {props.form_field.AllowedValues.map((allowed_value, i) => {
                                return (
                                    <MenuItem key={i} value={allowed_value}>{allowed_value}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    }
                </FormControl>
            </Grow>
            {showFieldChildren &&
                props.form_field.Children.map((f, i) => {
                    return <DynamicFormField form_field={f} validateField={props.validateField} key={i} isChild={true} />
                })
            }
        </>
    );
}
export default DynamicFormField;