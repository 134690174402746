import { Collapse, Paper } from '@mui/material';
import { useContext } from 'react';
import { ConfigContext } from '../../../App/App';
import StructuredDataResults from '../../../Chat/Body/Messages/StructuredData/StructuredDataResults';
import styles from './RelatedLinks.module.css';
function StructuredDataFooterItems(props: { showDataItems: boolean }) {
  const config = useContext(ConfigContext);
  return (
    <Collapse in={props.showDataItems} sx={{
      overflowY: "hidden",
      padding: '0px',
      overflow: 'hidden',
      display: 'inherit',
      "&&.MuiCollapse-entered": {
        height: '100% !important',
        overflow: 'hidden',
        display: 'inherit'
      },
      "&& .MuiCollapse-wrapper": {
        maxHeight: '100% !important',
        overflow: 'hidden',
        display: 'inherit',
        "&& .MuiCollapse-wrapperInner": {
          height: '100% !important',
          overflow: 'hidden',
          display: 'inherit'
        }
      }
    }}
      classes={{ root: styles.relatedLinksCollapse }}>
      <Paper sx={{
        borderRadius: '0px', background: 'white',
        height: '100%',
        width: '100%',
        borderLeft: `2px solid ${config.TertiaryColor}`,
        borderRight: `2px solid ${config.TertiaryColor}`,
        borderTop: '0px',
        borderBottom: '0px',
        overflow: 'hidden !important'
      }}
        className={styles.relatedLinks}>
        <StructuredDataResults startCollapsed={false} />
      </Paper >
    </Collapse>
  );
}
export default StructuredDataFooterItems; 