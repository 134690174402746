import { useContext } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import { AppContext } from '../../../App/AppContext';
import Message from './Message/Message';
import styles from './Messages.module.css';
const MessagesContent = () => {
  const { state } = useContext(AppContext);
  return (
    <>
      {state.messages && state.messages.map((msg, i) => {
        return <Message
          key={i}
          message={msg}
        />
      })}
    </>
  )
}
function Messages() {
  return (
    <ScrollToBottom className={styles.messagesWrapper} followButtonClassName={styles.messagesScrollBtn}>
      <MessagesContent />
    </ScrollToBottom>
  );
}
export default Messages;