import Header from '../Shared/Header/Header';
import WrapperBox from '../Shared/WrapperBox/WrapperBox';
import Body from './Body/Body';
import HeaderActions from './Header/HeaderActions/HeaderActions';
function Chat() {
  return (
    <WrapperBox>
      <Header pageName='Chat' left={<HeaderActions />} />
      <Body></Body>
    </WrapperBox>
  );
}
export default Chat;