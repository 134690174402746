import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Card, Link, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useContext } from 'react';
import ExpandMoreIcon from 'remixicon-react/ArrowDownSFillIcon';
import { ConfigContext } from '../../../../App/App';
import { AppContext } from '../../../../App/AppContext';

const StructuredDataCard = (props: { card: any }) => {
    const { state, dispatch } = useContext(AppContext);
    const config = useContext(ConfigContext);
    let title = ""
    let subtitle = ""
    let description1 = ""
    let description1FieldName = ""
    let description2 = ""
    let description2FieldName = ""
    let description3 = ""
    let description3FieldName = ""
    let description4 = ""
    let description4FieldName = ""
    let description5 = ""
    let description5FieldName = ""
    let link = ""
    let logo = ""
    Object.keys(state.formDetails.properties).map((c) => {
        let d = state.formDetails.properties[c].metadata[0]["card_field"]
        if (d === "Title") {
            title = props.card[c]
        }
        if (d === "Subtitle") {
            subtitle = props.card[c]
        }
        if (d === "Description1") {
            description1 = props.card[c]
            description1FieldName = state.formDetails.properties[c].title
        }
        if (d === "Description2") {
            description2 = props.card[c]
            description2FieldName = state.formDetails.properties[c].title
        }
        if (d === "Description3") {
            description3 = props.card[c]
            description3FieldName = state.formDetails.properties[c].title
        }
        if (d === "Description4") {
            description4 = props.card[c]
            description4FieldName = state.formDetails.properties[c].title
        }
        if (d === "Description5") {
            description5 = props.card[c]
            description5FieldName = state.formDetails.properties[c].title
        }
        if (d === "Link")
            link = props.card[c]
        if (d === "Logo")
            logo = props.card[c]
        return c;
    })
    return (
        <Card sx={{ background: '#e8e8e8' }}>
            <Accordion>
                <AccordionSummary
                    expandIcon={
                        <Box sx={{ padding: 0 }}><ExpandMoreIcon size="1.3rem" /></Box>
                    }
                    sx={{
                        display: 'flex', flexDirection: 'column', paddingLeft: `${config.PaddingNumber}px`, paddingRight: `${config.PaddingNumber}px`, margin: '0px !important', overflow: 'hidden', width: '100%', "& .MuiAccordionSummary-content": { width: '100%', flexDirection: 'column', marginBottom: '0px', marginTop: '8px !important' }, "& .MuiAccordionSummary-expandIconWrapper": { marginTop: '-15px' }, justifyContent: 'center', alignItems: 'center'
                    }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <ListItemAvatar sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar variant='rounded' sx={{
                                background: config.PrimaryColor, width: '60px', height: '60px', marginRight: '8px', "& img": {
                                    maxWidth: '90%',
                                    maxHeight: '90%'
                                }
                            }}>
                                <img
                                    src={logo}
                                    alt={subtitle}
                                />
                            </Avatar>
                            <Box sx={{ lineHeight: 1, paddingTop: '5px', display: 'none', gap: .5, alignItems: 'center' }}>
                                <Link href={link} target="_blank" sx={{ color: config.PrimaryColor, fontWeight: 'bold', textDecoration: 'none', lineHeight: 1, fontSize: '.75rem' }} rel="noopener noreferrer">Apply</Link>
                                <Typography sx={{ display: 'none', color: config.PrimaryColor, fontWeight: 'bold', textDecoration: 'none', lineHeight: 1, fontSize: '.75rem' }} onClick={() => dispatch({ type: "addSavedFormData", savedFormData: props.card })} >Save</Typography>
                            </Box>
                        </ListItemAvatar>
                        <Box sx={{ flex: 1 }}>
                            <ListItemText primary={title} secondary={subtitle} primaryTypographyProps={{ variant: "body2", fontWeight: 'bold', color: config.PrimaryColor }} secondaryTypographyProps={{ variant: "body2", fontSize: '.8rem' }} sx={{ marginBottom: '0px' }} />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '10)%' }}>
                        {/* <Button sx={{ display: 'flex', justifyContent: 'flex-start', padding: '4px 8px !important', fontWeight: 'bold', boxShadow: 'none' }}> */}
                        <Link href={link} target="_blank" sx={{ fontSize: '.8rem', textDecoration: 'none', fontWeight: 'bold', textTransform: 'none' }}>Apply Now</Link>
                        {/* </Button> */}
                        <Link sx={{ display: 'flex', justifyContent: 'center', padding: '4px 8px !important', fontWeight: 'bold', boxShadow: 'none', textDecoration: 'none', textTransform: 'none' }}>
                            {/* <SaveIcon size={12} color={state.savedFormData.includes(props.card) ? "gray" : "black"} /> */}
                            <Typography sx={{ marginLeft: '3px', fontSize: '.8rem', color: state.savedFormData.includes(props.card) ? "gray" : "black", textTransform: 'none', fontWeight: 'bold' }} onClick={(e) => { dispatch({ type: "addSavedFormData", savedFormData: props.card }); e.stopPropagation() }}>
                                {state.savedFormData.includes(props.card) ? "Saved" : "Save"}
                            </Typography>
                        </Link>
                    </Box>
                    {/* <Box sx={{ pl: 2, flex: '0 0 75%', overflow: 'hidden', lineHeight: '1', alignItems: 'start', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <div>
                            <Typography sx={{ fontSize: '1rem' }}>{title}</Typography>
                        </div>
                        <Box sx={{ width: '100%', overflow: 'hidden' }}>
                            <Typography variant="caption" sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: 'hidden', fontWeight: 'bold', lineHeight: '1' }}>{subtitle}</Typography>
                        </Box> */}
                    {/* </Box> */}
                </AccordionSummary>
                <AccordionDetails >
                    <Typography sx={{ display: 'flex', gap: 1, marginBottom: '5px', fontSize: '.9rem' }}>
                        <strong>{description1FieldName}
                        </strong> {description1}
                    </Typography>
                    {description2 && <Typography sx={{ display: 'flex', gap: 1, marginBottom: '5px', fontSize: '.9rem' }}>
                        <strong>{description2FieldName}
                        </strong> {description2}
                    </Typography>}
                    {description3 && <Typography sx={{ marginBottom: '5px', fontSize: '.9rem' }}>
                        <strong>{description3FieldName}
                        </strong> {description3}
                    </Typography>}
                    {description4 && <Typography sx={{ fontSize: '.9rem', marginBottom: '5px' }}>
                        <strong>{description4FieldName}
                        </strong> {description4}
                    </Typography>}
                    {description5 && <Typography sx={{ fontSize: '.9rem' }}>
                        <strong>{description5FieldName}
                        </strong> {description5}
                    </Typography>
                    }
                </AccordionDetails>
            </Accordion>
        </Card>
    )
}

export default StructuredDataCard;