import { v4 as uuidv4 } from 'uuid';
import { PageResult } from "../../API/api";
import { EdullaConfig } from "../../config/configType";
import { ChatMessage } from "../Shared/API/ApiTypes";
export const initialState: AppState = {
    enteredInformation: false,
    lastLocation: "/",
    shrunk: false,
    messages: [{ content: "Hello, how can I help you today?", direction: 1, time: new Date(Date.now()) }],
    relatedLinks: { newLinks: false, relatedLinks: [] },
    searchResults: { showSearchResults: false, showSearch: false, searching: false, searchResults: [], structuredResults: [], showLinks: false, searchQuery: "" },
    firstName: '',
    lastName: '',
    personalDetails: {},
    formDetails: {},
    filteredFormData: [],
    savedFormData: [],
    sessionid: uuidv4()
}

export type RelatedLinks = {
    newLinks: boolean,
    relatedLinks: PageResult[]
}

export type SearchResults = {
    showSearchResults: boolean,
    showSearch: boolean,
    searching: boolean,
    showLinks: boolean,
    searchQuery: string,
    searchResults: PageResult[],
    structuredResults: any[]
}

export type ContactInformation = {
    viewed: boolean,
    Title: string,
    Url: string,
    Email: string,
    Phone: string,
    Address: string
}

export type AppState = {
    enteredInformation: boolean,
    shrunk: boolean,
    lastLocation: string,
    messages: ChatMessage[]
    searchResults: SearchResults,
    relatedLinks: RelatedLinks,
    contactInformation?: ContactInformation
    firstName: string,
    lastName: string,
    personalDetails: any,
    formDetails: any,
    filteredFormData: any,
    savedFormData: any,
    sessionid: string
}

export const getStorageIdentifier = () => {
    return storageIdentifier + sessionStorage.getItem("edullaTid")
}

export type AppAction =
    | { type: 'setEnteredInformation', hasEnteredInformation: boolean }
    | { type: 'setShrunk', shrunk: boolean }
    | { type: 'addMessage', message: ChatMessage }
    | { type: 'updateTempMessage', message: ChatMessage }
    | { type: 'setRelatedLinks', relatedLinks: PageResult[] }
    | { type: 'setSearchResults', searchResults: PageResult[], structuredResults: any[], showSearchResults: boolean, showSearch: boolean, searching: boolean, showLinks: boolean, searchQuery: string }
    | { type: 'setShowSearchResults', showSearchResults: boolean }
    | { type: 'setShowSearch', showSearch: boolean }
    | { type: 'setSearchQuery', searchQuery: string }
    | { type: 'setShowSearchLinks', showSearchLinks: boolean }
    | { type: 'setRelatedLinksViewed', viewed: boolean }
    | { type: 'setContactInformationFromLinks', links: PageResult[], config: EdullaConfig }
    | { type: 'setContactInformationViewed', viewed: boolean }
    | { type: 'setFirstName', firstName: string }
    | { type: 'setLastName', lastName: string }
    | { type: 'setLastLocation', location: string }
    | { type: 'setPersonalDetails', personalDetails: any }
    | { type: 'setFormDetails', formDetails: any }
    | { type: 'setFilteredFormData', filteredFormData: any }
    | { type: 'addSavedFormData', savedFormData: any }

export const storageIdentifier = "edullaLocalApp"

const addNewMessage = (messages: ChatMessage[], message: ChatMessage): ChatMessage[] => {
    if (message.direction === 1 && messages.length > 0) {
        const lastIndex = messages.length - 1;
        const lastMessage = messages[lastIndex];
        if (lastMessage.temp) {
            messages[lastIndex].content = message.content;
            messages[lastIndex].time = message.time;
            messages[lastIndex].relatedLinks = message.relatedLinks;
            messages[lastIndex].cards = message.cards;
            messages[lastIndex].temp = false;
            return messages.filter(m => m.temp === false || !m.temp)
        } else {
            return [...messages.filter(m => !m.temp), message]
        }
    }
    else {
        return [...messages, message]
    }
}


const updateTempMessage = (messages: ChatMessage[], message: ChatMessage): ChatMessage[] => {
    if (message.direction === 1 && messages.length > 0) {
        const lastIndex = messages.length - 1;
        const lastMessage = messages[lastIndex];
        if (lastMessage.temp && !message.relatedLinks) {
            messages[lastIndex].content = messages[lastIndex].content + message.content;
            messages[lastIndex].time = message.time;
            messages[lastIndex].relatedLinks = message.relatedLinks;
            messages[lastIndex].cards = message.cards;
            messages[lastIndex].temp = true;
            return messages
        } else if (message.relatedLinks) {
            messages[lastIndex].time = message.time;
            messages[lastIndex].relatedLinks = message.relatedLinks;
            messages[lastIndex].cards = message.cards;
            messages[lastIndex].temp = false;
            return messages.filter(m => !m.temp)
        } else {
            return [...messages.filter(m => !m.temp), message]
        }
    }
    else {
        return [...messages, message]
    }
}

const parseContactInformationFromLinks = (links: PageResult[], config: EdullaConfig) => {
    const contactInfoAll = config.ContactInformation
    const re = new RegExp("^([a-z][a-z0-9+-.]*:(//[^/?#]+)?)?(/?[a-z0-9-._~%!$&'()*+,;=@]+)");
    if (contactInfoAll && contactInfoAll.length > 0 && links) {
        const baseUrls = links.map((l) => {
            let match = l.url.match(re);
            if (match && match.length > 0) {
                return match[0]
            }
            else {
                return ""
            }
        });
        let compare = "";
        let mostFreq = "";

        baseUrls.reduce((acc: any, val) => {
            if (val in acc) {               // if key already exists
                acc[val]++;                // then increment it by 1
            } else {
                acc[val] = 1;      // or else create a key with value 1
            }
            if (acc[val] > compare) {   // if value of that key is greater
                // than the compare value.
                compare = acc[val];    // than make it a new compare value.
                mostFreq = val;        // also make that key most frequent.
            }
            return acc;
        }, {})
        let result = contactInfoAll.find((c) => c.Url === mostFreq);
        return result;
    }
    return undefined
}

export const appReducer = (state: AppState, action: AppAction) => {
    switch (action.type) {
        case "setEnteredInformation":
            return { ...state, enteredInformation: action.hasEnteredInformation };
        case "setShrunk":
            return { ...state, shrunk: action.shrunk };
        case "addMessage":
            return { ...state, messages: addNewMessage(state.messages, action.message) };
        case "updateTempMessage":
            return { ...state, messages: updateTempMessage(state.messages, action.message) };
        case "setRelatedLinks":
            return { ...state, relatedLinks: { newLinks: true, relatedLinks: action.relatedLinks } };
        case "setSearchResults":
            return { ...state, searchResults: { showSearchResults: action.showSearchResults, searchResults: action.searchResults, structuredResults: action.structuredResults, showSearch: action.showSearch, searching: action.searching, showLinks: action.showLinks, searchQuery: action.searchQuery } };
        case "setShowSearchResults":
            return { ...state, searchResults: { ...state.searchResults, showSearchResults: action.showSearchResults } };
        case "setShowSearch":
            return { ...state, searchResults: { ...state.searchResults, showSearch: action.showSearch } };
        case "setShowSearchLinks":
            return { ...state, searchResults: { ...state.searchResults, showLinks: action.showSearchLinks } };
        case "setSearchQuery":
            return { ...state, searchResults: { ...state.searchResults, searchQuery: action.searchQuery } };
        case "setRelatedLinksViewed":
            return { ...state, relatedLinks: { newLinks: !action.viewed, relatedLinks: state.relatedLinks.relatedLinks } };
        case "setContactInformationFromLinks":
            return { ...state, contactInformation: parseContactInformationFromLinks(action.links, action.config) };
        case "setContactInformationViewed":
            if (state.contactInformation) {
                return { ...state, contactInformation: { Title: state.contactInformation?.Title, Url: state.contactInformation?.Url, Email: state.contactInformation?.Email, Phone: state.contactInformation?.Phone, Address: state.contactInformation?.Address, viewed: action.viewed } };
            }
            return state;
        case "setFirstName":
            return { ...state, firstName: action.firstName };
        case "setLastName":
            return { ...state, lastName: action.lastName };
        case "setLastLocation":
            return { ...state, lastLocation: action.location };
        case "setPersonalDetails":
            return { ...state, personalDetails: action.personalDetails };
        case "setFormDetails":
            return { ...state, formDetails: action.formDetails };
        case "setFilteredFormData":
            return { ...state, filteredFormData: action.filteredFormData };
        case "addSavedFormData":
            if (state.savedFormData.includes(action.savedFormData)) {
                return { ...state, savedFormData: [...state.savedFormData.filter((item: any) => item !== action.savedFormData)] }
            }
            else {
                return { ...state, savedFormData: [...state.savedFormData, action.savedFormData] }
            }
        default:
            return state;
    }

};
