import { Box, Button } from '@mui/material';
import { useContext, useState } from 'react';
import { ConfigContext } from '../../../../App/App';
import { AppContext } from "../../../../App/AppContext";
import StructuredDataFilters from './StructuredDataFilters';
import StructuredDataResultsList from './StructuredDataResultsList';

const StructuredDataResults = (props: { cards?: any, startCollapsed: boolean }) => {
    const { state } = useContext(AppContext);
    const config = useContext(ConfigContext);
    const [showAll, setShowAll] = useState(true);
    let items = props.cards ? props.cards : showAll ? state.filteredFormData : state.savedFormData;
    return (
        <Box sx={{
            width: '100%', display: 'flex', gap: 2, flexWrap: 'wrap', maxHeight: '100%', paddingTop: `${config.PaddingNumber}px`, overflowY: 'auto !important', bgcolor: config.TextInputBackground
        }}>
            <Box sx={{
                width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'stretch',
                paddingLeft: `${config.PaddingNumber}px`,
                paddingRight: `${config.PaddingNumber}px`,
                gap: '0px'
            }}>
                <Button sx={{ width: "50%", fontWeight: 'bold', background: (showAll ? `${config.PrimaryColor} !important` : ''), color: (!showAll ? `${config.PrimaryColor} !important` : 'white'), lineHeight: 1, padding: '8px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }} onClick={() => setShowAll(true)}>
                    Show All
                </Button>
                <Button sx={{ width: "50%", fontWeight: 'bold', background: (!showAll ? `${config.PrimaryColor} !important` : ''), color: (showAll ? `${config.PrimaryColor} !important` : 'white'), lineHeight: 1, padding: '8px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => setShowAll(false)}>
                    Saved

                </Button>
                {/* {Object.keys(state.formDetails.properties).map((k) => {
                    return <Card key={k} sx={{ padding: "8px", fontSize: ".8rem" }}>{k}</Card>

                })} */}
            </Box>
            <StructuredDataFilters cards={items} />
            <Box sx={{
                overflowY: 'hidden', display: 'flex', flexDirection: 'column', gap: `${config.PaddingNumber}px`, height: '100%', width: '100%', paddingBottom: `${config.PaddingNumber}px`,
                paddingLeft: `${config.PaddingNumber}px`,
                paddingRight: `${config.PaddingNumber}px`
            }}>
                <StructuredDataResultsList cards={items} startCollapsed={props.startCollapsed} />
            </Box>
        </Box>
    )
}

export default StructuredDataResults;