import { List, ListItem } from '@mui/material';
import styles from './StructuredData.module.css';
import StructuredDataCard from './StructuredDataCard';

const StructuredDataResultsList = (props: { cards?: any, startCollapsed: boolean }) => {
    return (
        <List className={props.startCollapsed ? styles.structuredDataCards : ""} sx={{ width: '100%', gap: 1, display: 'flex', flexDirection: 'column' }}>
            {props.cards && props.cards.map((k: any, index: any) => {
                return (
                    <ListItem key={index} sx={{ padding: '0px' }}>
                        <StructuredDataCard card={k} />
                    </ListItem>)
            })}
        </List>
    )
}

export default StructuredDataResultsList;