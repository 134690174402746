import { ContactInformation } from "../Components/App/appReducer"

export type EdullaConfig = {
    LogoUrl: string
    SquareLogoUrl: string
    TypeOfBot: string
    WelcomeMessage: string
    SmallHeaderLogoUrl: string
    HeaderBackgroundImageHome: string
    PrimaryColor: string
    SecondaryColor: string
    TertiaryColor: string
    TextInputBackground: string
    BoxShadow: string
    BorderRadiusNumber: number
    Gap: string
    PaddingNumber: number
    Border: string
    FontFamily: string
    TransparentBackground: string
    ButtonBackground: string
    MuiFilledInputBackground: string
    HomeHeadline1: string
    HomeHeadline2: string
    HomeHeadline3: string
    HomeAskAQuestionHeadline1: string
    HomeAskAQuestionHeadline2: string
    IndexName: string
    HomeSearchHeadline1: string
    HomeSearchHeadline2: string
    RequireForm: boolean
    CustomForm?: boolean
    EntranceForm?: any
    ContactInformation?: ContactInformation[]
}

export const configInitial: EdullaConfig = {
    LogoUrl: "",
    SquareLogoUrl: "",
    WelcomeMessage: "Hello, how can I help you today?",
    TypeOfBot: "chat",
    SmallHeaderLogoUrl: "",
    HeaderBackgroundImageHome: "",
    PrimaryColor: "",
    SecondaryColor: "",
    TertiaryColor: "",
    TextInputBackground: "",
    BoxShadow: "",
    BorderRadiusNumber: 0,
    Gap: "",
    PaddingNumber: 0,
    Border: "",
    FontFamily: "",
    TransparentBackground: "",
    ButtonBackground: "",
    MuiFilledInputBackground: "",
    HomeHeadline1: "",
    HomeHeadline2: "",
    HomeHeadline3: "",
    IndexName: "",
    HomeAskAQuestionHeadline1: "",
    HomeAskAQuestionHeadline2: "",
    HomeSearchHeadline1: "",
    HomeSearchHeadline2: "",
    RequireForm: true,
    CustomForm: false,
    EntranceForm: {}
}