import * as Sentry from '@sentry/browser'

enum EFeature1Context {
    visitor = 'visitor'
}


export const contextNames = {
    ...EFeature1Context
}

export type TContextName = keyof typeof contextNames
export type TFeatureName = 'edulla-chat' | 'edulla-chat-start'

export const logLevel = 'error'

export function log(
    featureName: TFeatureName,
    errorMessage: string,
    contextName: TContextName,
    properties: object,
    level: Sentry.SeverityLevel = 'error',
) {
    Sentry.setContext(contextName, properties)

    Sentry.withScope((scope) => {
        scope.setTag('feature', featureName)
        scope.setLevel(level)
        Sentry.captureException(new Error(errorMessage))
    })
}