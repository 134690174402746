import { List, Typography } from '@mui/material';
import React from 'react';
import { ConfigContext } from '../../../App/App';
import Result from './Result';
import styles from './Results.module.css';
function Results() {
  const config = React.useContext(ConfigContext);
  return (
    <div className={styles.searchResultsWrapper}>
      <Typography component="div" sx={{ fontWeight: "bold", color: config.PrimaryColor }} >
        Top Pages
      </Typography>
      <List sx={{ width: '100%' }}>
        <Result />
        <Result />
        <Result />
        <Result />
        <Result />
        <Result />
        <Result />
      </List>
    </div>
  );
}
export default Results; 