import { Container } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { useContext } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { log } from '../../Utilities/logging';
import { configInitial } from '../../config/configType';
import { useTheme } from '../../theme';
import Chat from '../Chat/Chat';
import Start from '../Home/Start';
import Search from '../Search/Search';
import Footer from '../Shared/Footer/Footer';
import Splash from '../Splash/Splash';
import styles from './App.module.css';
import { AppContext, AppProvider } from './AppContext';
import GradientBG from './GradientBG';
export const appWrapperId = "edullaAppWrapper"

export type FrameAction = {
  isEdulla: boolean,
  action: string,
  data: any
}
export const ConfigContext = React.createContext(configInitial)

function App() {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { theme, config, themeLoaded } = useTheme();


  // useEffect(() => {
  //   try {
  //     api.logEvent.logEvent({ eventtype: EventTypes.PageVisit.toString(), eventvalue: window.location.href.toString(), indexname: config.IndexName, sessionid: state.sessionid, pageurl: window.location.href.toString() })
  //   } catch (e) {
  //     log("edulla-chat", (e as Error).message, "visitor", { sessionId: state.sessionid })
  //   }
  // }, [config.IndexName, state.sessionid]);

  window.addEventListener('message', function (event) {
    let msgAction = event.data as FrameAction;
    if (msgAction.isEdulla) {
      switch (msgAction.action) {
        case "requestState":
          sendMessageToParent({ isEdulla: true, action: "edullaChatState", data: state });
          break;
        case "navigate":
          navigate(msgAction.data)
          break;
        default:
          break;
      }
      sendMessageToParent(event.data);
    }
  })

  const sendMessageToParent = (message: FrameAction) => {
    try {
      const edullaStart = document.getElementById('edullaStart') as HTMLIFrameElement;
      if (edullaStart.contentWindow && !window.parent.location.host.includes("localhost")) {
        edullaStart.contentWindow.postMessage(message, "*");
      }
    }
    catch (e) {
      log("edulla-chat", (e as Error).message, "visitor", { sessionId: state.sessionid })
    }
  }
  if (themeLoaded) {
    return (
      <ThemeProvider theme={theme}>
        <AppProvider>
          <ConfigContext.Provider value={config}>
            <Container id={appWrapperId} className={styles.appWrapper}>
              <Routes>
                <Route path="/Home" element={<Splash />} />
                <Route path="/" element={<Start />} />
                <Route path="/Chat" element={<Chat />} />
                <Route path="/Search" element={<Search />} />
                <Route
                  path="*"
                  element={<Navigate to="/" replace />}
                />
              </Routes>

              <GradientBG />
              {(pathname !== "/" || state.enteredInformation) &&
                <Footer></Footer>}
            </Container>
          </ConfigContext.Provider>
        </AppProvider>
      </ThemeProvider >
    );
  }
  else {
    return (<></>)
  }
}
export default App;