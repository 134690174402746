import { Card, FilledInput, FormControl, InputAdornment, InputLabel } from '@mui/material';
import SearchIcon from 'remixicon-react/Search2FillIcon';
import styles from './Body.module.css';
import Results from './Results/Results';

function Body() {
  return (
    <div className={styles.searchBodyWrapper}>
      <Card sx={{ p: 0 }} className={styles.messageSender} >
        <FormControl variant="filled" fullWidth>
          <InputLabel htmlFor="my-input">Start a Search</InputLabel>
          <FilledInput id="my-input" aria-describedby="my-helper-text" disableUnderline endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          } fullWidth autoFocus />
        </FormControl>
      </Card>
      <Card sx={{ p: 2, mt: 2 }} className={styles.searchBody}>
        <Results />
      </Card>
    </div>
  );
}
export default Body; 