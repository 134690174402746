import { FilledInput, FormControl, InputLabel } from "@mui/material";

import { useContext } from "react";
import { ConfigContext } from "../../../../App/App";
import { AppContext } from "../../../../App/AppContext";

const StructuredDataFilter = (props: { filter: any, cards?: any }) => {
    const config = useContext(ConfigContext);
    const { state: { personalDetails } } = useContext(AppContext);
    let filterType = props.filter.type;
    let displayType: string = "";
    let filterList: any[] = []
    let title = props.filter.title;
    let value = personalDetails[title] ?? "";
    if (filterType === "string") {
        for (var i = 0; i < props.cards.length; i++) {
            let propertyValue = props.cards[i][title];
            console.log(propertyValue)
            if (!filterList.includes(propertyValue)) {
                filterList.push(propertyValue)
            }
        }
        if (filterList.length > 5) {
            displayType = "Free"
        }
        else {
            displayType = "Select"
        }

    }
    return (
        <>
            {displayType === "Free" &&
                <FormControl variant="filled" sx={{ width: `calc(50% - ${config.PaddingNumber / 2}px)` }}>
                    <InputLabel htmlFor="my-input">{title}</InputLabel>
                    <FilledInput
                        disableUnderline={true} id="my-input" aria-describedby="my-helper-text"
                        value={value} />
                </FormControl>
            }
        </>
    )
}

export default StructuredDataFilter;