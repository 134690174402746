import "@fontsource/inter";
import CssBaseline from '@mui/material/CssBaseline';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './Components/App/App';
import './index.css';
import reportWebVitals from './reportWebVitals';
Sentry.init({
  dsn: "https://c65da4d3fe024e0e883b825b40e22f7f@o424565.ingest.sentry.io/4505324735627264",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const tid = new URLSearchParams(window.location.search)?.get("tid") ?? "";
window.sessionStorage.setItem("edullaTid", tid);
root.render(
  <Router>
    <CssBaseline />
    <App />
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
