import { useEffect } from "react";
import { useLocalStorage } from "../Components/Shared/Hooks/useLocalStorage";
import { log } from "../Utilities/logging";
import { configInitial, EdullaConfig } from "./configType";

const readTidFromScriptSrc = () => {
    const edullaScript = document.currentScript
    const edullaScriptSrc = edullaScript?.getAttribute('src') || "Default";
    const url = new URL(edullaScriptSrc)
    const queryParams = new URLSearchParams(url.search)
    const tid = queryParams.get('tid') || 'Default'
    return tid
}

export function useConfig() {
    let tid = window.sessionStorage.getItem("edullaTid");
    if (!tid) {
        const queryParams = new URLSearchParams(window.location.search)
        tid = queryParams.get("tid") || readTidFromScriptSrc();
        tid = tid.replace("/", "");
    }
    let [config, setConfig] = useLocalStorage<EdullaConfig>(`edullaConfig-${tid}`, configInitial)

    // const mergeConfig = (config: any, newConfig: any) => {
    //     Object.keys(newConfig).forEach(function (key) {
    //         if (newConfig[key]) {
    //             config[key] = newConfig[key]
    //         }
    //     });
    //     setConfig(config)
    // }

    useEffect(() => {
        if (config === configInitial) {
            const fetchJson = () => {
                fetch(`https://production-edulla-bot-interface.s3.amazonaws.com/configs/${tid}.json`)
                    .then((response) => {
                        return response.json()
                    })
                    .then((data) => {
                        setConfig(data)
                    })
                    .catch((e) => {
                        log("edulla-chat", (e as Error).message, "visitor", { sessionId: "", indexName: tid })
                    })
            }
            fetchJson()
        }
    }, [config, setConfig, tid])
    return config;
} 