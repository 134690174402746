import Markdown from 'react-markdown';
import LinkRenderer from './LinkRenderer';
import styles from './Message.module.css';

function MessageContent(props: { messageContent: string }) {
    return (
        <Markdown components={{
            a: ({
                node,
                ...props
            }: any
            ) => <LinkRenderer {...props} />,
        }} className={styles.chatMessageContent}>
            {props.messageContent}
        </Markdown>
    )
}
export default MessageContent;