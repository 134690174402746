import { useContext } from 'react';
import api from "../API/api";
import { ConfigContext } from "../Components/App/App";
import { AppContext } from "../Components/App/AppContext";
import { AppState } from '../Components/App/appReducer';
import { CatalogCard, ChatMessage } from "../Components/Shared/API/ApiTypes";

export function useMessageSender() {
  let tempLinks = ""
  let tempMessageString = ""
  const { state, dispatch } = useContext(AppContext);
  const config = useContext(ConfigContext);
  const handleResponseStream = (msg: any, msgType: string) => {
    try {
      if (msgType === "NewMessage") {
        if (msg.includes("(") || tempMessageString.includes("(")) {
          tempMessageString = tempMessageString + msg
          let parenIndex = tempMessageString.indexOf("(")
          if (parenIndex < (tempMessageString.length - 1) && tempMessageString[parenIndex + 1] !== "S") {
            let tempMessage: ChatMessage = { content: msg, direction: 1, time: new Date(Date.now()), temp: true };
            dispatch({ type: "updateTempMessage", message: tempMessage })
          }
        }
        else {
          let tempMessage: ChatMessage = { content: msg, direction: 1, time: new Date(Date.now()), temp: true };
          dispatch({ type: "updateTempMessage", message: tempMessage })
        }
      }
      if (msgType === "Links") {
        tempLinks = tempLinks + msg;
      }
      if (msgType === "Closed") {
        let links = JSON.parse(tempLinks);
        let newMessage: ChatMessage = { content: "", direction: 1, relatedLinks: links, time: new Date(Date.now()), temp: false };
        dispatch({ type: "updateTempMessage", message: newMessage })
        tempLinks = "";
      }
    }
    catch (e) {

    }
  }

  const messageFailed = () => {
    let newMessage: ChatMessage = { content: "Sorry, there was a problem finding an answer. You can try asking again.", direction: 1, time: new Date(Date.now()) };
    dispatch({ type: "addMessage", message: newMessage })
  }

  //   export interface CatalogCard {
  //     collegeName: string;
  //     colorLogo: string;
  //     applyUrl: string;
  //     admissionsPhone: string;
  //     admissionsEmail: string;
  //     admissionsUrl: string;
  //     programs: CatalogProgram[];
  // }
  const mapFormDetailsToCards = (filteredFormData: any): CatalogCard[] => {
    let result: CatalogCard[] = []
    result = filteredFormData.map((f: any) => {
      console.log(f)
      let card: CatalogCard = {
        collegeName: f["College"],
        applyUrl: f["Apply Link"],
        colorLogo: "https://www.southplainscollege.edu/_resources/images/structure/spc-badge.png",
        admissionsEmail: "",
        admissionsPhone: "",
        admissionsUrl: "",
        programs: [{
          awardLevel: f["Award Level"],
          campus: f["Campus"],
          deliveryMethod: f["Delivery Method"],
          program: f["Program"],
          programDetails: f["Program Details"],
          term: f["Term"]
        }]
      };
      return card;
    })
    return result
  }
  const sendCatalogMessage = async (message: ChatMessage, lastMessages: ChatMessage[], state: AppState) => {
    console.log("Sending Catalog Message")
    let result = await api.catalog.ask({ question: message.content, index: config.IndexName, context: lastMessages, sessionid: state.sessionid, personal_details: state.personalDetails })
    if (result?.response) {
      //TODO Map Items to Cards
      let cards = mapFormDetailsToCards(result.filtered_items);
      cards = []
      console.log(cards)
      let newMessage: ChatMessage = { content: result.response, direction: 1, time: new Date(Date.now()), relatedLinks: result.links, cards: result.cards };
      dispatch({ type: "addMessage", message: newMessage })
      dispatch({ type: "setContactInformationFromLinks", links: result.links ?? [], config: config })
      dispatch({ type: "setPersonalDetails", personalDetails: result.personal_details ?? {} })
      dispatch({ type: "setFormDetails", formDetails: result.form_details ?? {} })
      dispatch({ type: "setFilteredFormData", filteredFormData: result.filtered_items ?? {} })
    }
    else {
      console.log(result)
      messageFailed()
    }
  }


  const sendMessage = async (message: ChatMessage) => {
    let messageCount = state.messages.length
    let startIndex = messageCount > 3 ? messageCount - 4 : 0;
    let lastMessages = state.messages.slice(startIndex, messageCount - 1)
    if (config.IndexName === "mobap"){
      lastMessages = state.messages
    }
    dispatch({ type: "addMessage", message })
    let tempMessage: ChatMessage = { content: "", direction: 1, time: new Date(Date.now()), temp: true };
    dispatch({ type: "addMessage", message: tempMessage })
    try {
      if (config.TypeOfBot?.includes("catalog")) {
        console.log("catalog")
        await sendCatalogMessage(message, lastMessages, state)
      }
      else {
        // try {
        //   await api.questionStream.ask({ question: message.content, index: config.IndexName, context: lastMessages, sessionid: state.sessionid }, handleResponseStream)
        // }
        // catch (e) {
        //   console.log(e)
        if (message.content === "") {
          await api.questionStream.ask({ question: message.content, index: config.IndexName, context: lastMessages, sessionid: state.sessionid }, handleResponseStream)
        }
        let result = await api.question.ask({ question: message.content, index: config.IndexName, context: lastMessages, sessionid: state.sessionid })
        if (result?.answer) {
          let result_answer = result.answer.replace(/\(Score: \d{1,3}\)/, '')
          result_answer = result_answer.replace(/\(score: \d{1,3}\)/, '')
          let newMessage: ChatMessage = { content: result_answer, direction: 1, time: new Date(Date.now()), relatedLinks: result.links, cards: result.cards };
          dispatch({ type: "addMessage", message: newMessage })
          dispatch({ type: "setContactInformationFromLinks", links: result.links, config: config })
        }
        else {
          messageFailed()
          // }
        }

      }
    }
    catch (e) {
      console.log(e)
      messageFailed()
    }
  }
  return sendMessage;
}
