import { useCallback, useReducer } from 'react';
import { useLocalStorage } from 'react-use';

export const usePersistReducer = (initialState: any, storageKey: string, reducer: any) => {
    const currentState = localStorage.getItem(storageKey);
    initialState = currentState ?? initialState;
    const [savedState, saveState] = useLocalStorage(
        storageKey,
        initialState,
    )

    const reducerLocalStorage = useCallback(
        (state: any, action: any) => {
            const newState = reducer(state, action)

            saveState(newState)

            return newState
        },
        [saveState, reducer],
    )

    return useReducer(reducerLocalStorage, savedState)
}
