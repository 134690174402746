import axios, { AxiosError, AxiosResponse } from 'axios';
import { CatalogCard, ChatMessage } from '../Components/Shared/API/ApiTypes';
export enum EventTypes {
    PageVisit = "PageVisit",
    ChatOpened = "ChatOpened",
    InformationEntered = "InformationEntered",
    QuestionAsked = "QuestionAsked",
    SearchMade = "SearchMade"
}

export interface LogEvent {
    eventtype: string | null;
    eventvalue: string | null;
    indexname: string | null;
    pageurl: string | null;
    sessionid: string | null;
}
export interface LogEventResponse {
    success: boolean;
}
interface Question {
    question: string;
    index: string | null;
    sessionid: string | null;
    context: ChatMessage[];
}
interface SubmitInformation {
    indexname: string | null;
    sessionid: string | null;
    firstname: string | null;
    lastname: string | null;
    email: string | null;
    visitortype: string | null;
}

interface SubmitDynamicInformationField {
    fieldid: string,
    fieldvalue: string
}

interface SubmitDynamicInformation {
    indexname: string | null;
    sessionid: string | null;
    objectid: string | null;
    fields: SubmitDynamicInformationField[]
}

interface Query {
    query: string;
    index: string | null;
    sessionid: string | null;
}

export interface PageResult {
    url: string;
    title: string;
}

export interface Answer {
    answer: string;
    links: PageResult[];
    cards?: CatalogCard[];
}

export interface CatalogRequest {
    question: string;
    index: string;
    sessionid: string;
    personal_details: any;
    context: ChatMessage[];
}

export interface CatalogResponse {
    response: any;
    personal_details: any;
    filtered_items: any;
    form_details: any;
    links?: PageResult[];
    cards?: CatalogCard[];
}

const newAPIUrl = 'https://botapi2.app.edulla.ai';
const newAPIKey = '$78PR!~VODUlSs3;'
// const baseUrl = 'https://botapi.app.edulla.ai'
const baseUrl = window.location.href.includes("localhost") ? 'https://botapi2.app.edulla.ai' : 'https://botapi2.app.edulla.ai';
const apiKey = window.location.href.includes("localhost") ? '$78PR!~VODUlSs3;' : '$78PR!~VODUlSs3;';

axios.defaults.baseURL = baseUrl
// axios.defaults.baseURL = 'http://localhost:8000'
axios.interceptors.response.use(
    (res) => res,
    (error: AxiosError) => {
        const { data, status } = error.response!;
        switch (status) {
            case 400:
                console.error(data);
                break;
            case 401:
                console.error('unauthorised');
                break;
            case 404:
                console.error('/not-found');
                break;
            case 500:
                console.error('/server-error');
                break;
        }
        return Promise.reject(error);
    }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const request = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}, baseUrlOverride = baseUrl, apiKeyOverride = apiKey) =>
        axios<T>({
            url,
            baseURL: baseUrlOverride,
            method: 'post',
            headers: { 'Content-Type': 'application/json', 'x-api-key': apiKeyOverride },
            data: body
        }).then(responseBody)
};

const question = {
    ask: (data: Question) => {
        let isBeta = localStorage.getItem("edullabeta");
        if (isBeta === "apitest" || beta_schools.includes(data.index ?? ''))
            return request.post<Answer>('/question/', JSON.stringify(data), newAPIUrl, newAPIKey)
        if(data.index ==="mobap")
            return request.post<Answer>('/question_custom_prompt/', JSON.stringify(data))
        return request.post<Answer>('/question/', JSON.stringify(data))
    }
};

const search = {
    start: (data: Query) => {
        let isBeta = localStorage.getItem("edullabeta");
        if (isBeta === "apitest" || beta_schools.includes(data.index ?? ''))
            return request.post<Answer>('/search/', JSON.stringify(data), newAPIUrl, newAPIKey)
        return request.post<Answer>('/search/', JSON.stringify(data))
    }
};

const searchCatalog = {
    start: (data: CatalogRequest) => {
        return request.post<CatalogResponse>('/catalogsearch/', JSON.stringify(data))
    }
};

const catalog = {
    ask: (data: CatalogRequest) => {
        return request.post<CatalogResponse>('/catalog/', JSON.stringify(data), newAPIUrl, newAPIKey)
    }
};
// # TCSG    01881214-7444-42e3-a6b8-9bf15f2a26e9
// # CYPRESS 018902ac-2d83-4d7c-b522-a422370b1d86
// # ATHENS  01884fad-8117-4cdf-b4d3-8157dffb16ff
// # OFTC    01884fac-b324-4b4f-b99f-0a6edc5e4970
let beta_schools = ['tcsg', 'cypresscollege', 'athenstech', 'oftc', 'twu', 'malone', 'bethanywv', 'belmontabbeycollege'];

const submitInformation = {
    submitInformation: (data: SubmitInformation) => {
        let isBeta = localStorage.getItem("edullabeta");
        if (isBeta === "apitest" || beta_schools.includes(data.indexname ?? ''))
            return request.post<Answer>('/submitinformation/', JSON.stringify(data), newAPIUrl, newAPIKey)
        return request.post<Answer>('/submitinformation/', JSON.stringify(data))
    }
};
const submitDyanmicInformation = {
    submitDyanmicInformation: (data: SubmitDynamicInformation) => {
        let isBeta = localStorage.getItem("edullabeta");
        if (isBeta === "apitest" || beta_schools.includes(data.indexname ?? ''))
            return request.post<Answer>('/submitdyanmicinformation/', JSON.stringify(data), newAPIUrl, newAPIKey)
        return request.post<Answer>('/submitdyanmicinformation/', JSON.stringify(data))
    }
};
const questionStream = {
    ask: (data: Question, callback: (msg: any, msgType: string) => void) => {
        let lastMessagesString = JSON.stringify(data.context);
        let lastMessagesEncoded = btoa(lastMessagesString);
        const evtSource = new EventSource(`${baseUrl}/questionstream?question=${data.question}&index=${data.index}&sessionid=${data.sessionid}&api-key=${encodeURIComponent(apiKey)}&context=${lastMessagesEncoded}`)
        evtSource.addEventListener('new_message', function (event) {
            callback(event.data, "NewMessage")
        })
        evtSource.addEventListener('links', function (event) {
            callback(event.data, "Links")
        })
        evtSource.addEventListener('end_event', function (event) {
            callback(event.data, "Closed")
            evtSource.close()
        })
    }
}


const logEvent = {
    logEvent: (data: LogEvent) => {
        return request.post<LogEventResponse>('/logevent/', JSON.stringify(data))
    }
};
const api = {
    question,
    questionStream,
    searchCatalog,
    search,
    catalog,
    submitInformation,
    submitDyanmicInformation,
    logEvent
};

export default api;