import { Box, Collapse } from '@mui/material';
import { useContext, useState } from 'react';
import ExpandMoreIcon from 'remixicon-react/ArrowDownSFillIcon';
import { ConfigContext } from '../../../../App/App';
import { AppContext } from '../../../../App/AppContext';
import StructuredDataFilter from './StructuredDataFilter';

const StructuredDataFilters = (props: { cards?: any }) => {
    const config = useContext(ConfigContext);
    const [showFilters, setShowFilters] = useState(false);
    const getItemsFilters = (formDetails: any) => {
        let result = [];
        if (formDetails?.properties) {
            for (const property in formDetails.properties) {
                if (formDetails.properties[property].metadata[0].collect_via_bot) {
                    result.push(formDetails.properties[property]);
                }
            }
        }
        return result;
    }
    const { state: { formDetails } } = useContext(AppContext);
    let filters = getItemsFilters(formDetails)
    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingLeft: `${config.PaddingNumber}px`, paddingRight: `${config.PaddingNumber}px` }}>
            <Box sx={{ padding: `${config.PaddingNumber}px`, background: 'white', borderRadius: `${config.BorderRadiusNumber}px`, boxShadow: config.BoxShadow }}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} onClick={() => setShowFilters(!showFilters)}>Filters<ExpandMoreIcon /></Box>
                <Collapse in={showFilters}>
                    <Box sx={{ width: '100%', display: 'flex', gap: 1, marginTop: `${config.PaddingNumber}px`, flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        {filters.map((f: any, i: number) => {
                            return <StructuredDataFilter key={i} filter={f} cards={props.cards} />
                        })}
                    </Box>
                </Collapse>
            </Box>
        </Box>
    )
}

export default StructuredDataFilters;