import { Collapse, List, ListItem, Paper } from '@mui/material';
import { useContext } from 'react';
import { ConfigContext } from '../../../App/App';
import { AppContext } from '../../../App/AppContext';
import styles from './RelatedLinks.module.css';
function RelatedLinks(props: { showLinks: boolean }) {
  const config = useContext(ConfigContext);
  const { state } = useContext(AppContext);
  return (
    <Collapse in={props.showLinks} sx={{
      overflowY: "hidden",
      height: '0px',
      padding: '0px',
      marginTop: '-1px'
    }}
      classes={{ root: styles.relatedLinksCollapse }}>
      <Paper sx={{
        borderRadius: '0px', background: 'white',
        overflowY: "auto",
        maxHeight: '100%',
        width: '100%',
        borderLeft: `2px solid ${config.TertiaryColor}`,
        borderRight: `2px solid ${config.TertiaryColor}`,
        borderTop: '0px',
        borderBottom: '0px'
      }}
        className={styles.relatedLinks}>
        <List sx={{ p: 2, fontSize: '.8rem' }}>
          {state.relatedLinks.relatedLinks && state.relatedLinks.relatedLinks.map((l, i) => {
            return <ListItem key={i} sx={{ borderLeft: `3px solid ${config.SecondaryColor}`, marginBottom: 1, color: config.PrimaryColor }}><a href={l.url} target="_blank" rel="noreferrer">{l.title}</a></ListItem>
          })}
        </List>
      </Paper >
    </Collapse>
  );
}
export default RelatedLinks; 