import { Card, FilledInput, FormControl, InputAdornment, InputLabel } from '@mui/material';
import { useState } from 'react';
import SendIcon from 'remixicon-react/SendPlane2FillIcon';
import { useMessageSender } from '../../../../Utilities/SendMessage';
import styles from './MessageSender.module.css';
function MessageSender() {

  const [sending, setSending] = useState(false);
  const sendMessage = useMessageSender();

  const [inputMessage, setInputMessage] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputMessage(event.target.value);
  };

  const onKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputMessage !== "") {
      await onSend();
    }
  }

  const onSend = async () => {
    if (inputMessage !== "") {
      let newQuestion = inputMessage
      setSending(true);
      setInputMessage("");
      await sendMessage({ content: newQuestion, direction: 0, time: new Date(Date.now()) })
      setSending(false);
    }
  }

  return (

    <Card sx={{ p: 0 }} className={styles.messageSender} >
      <FormControl variant="filled" disabled={sending}>
        <InputLabel htmlFor="my-input">Send a Message</InputLabel>
        <FilledInput endAdornment={
          <InputAdornment position="end">
            <SendIcon onClick={onSend} className={(sending ? styles.messageSenderIconSending : "")} />
          </InputAdornment>
        }
          autoFocus
          onKeyUp={onKeyUp}
          value={inputMessage}
          onChange={handleChange}
          disableUnderline={true} id="my-input" aria-describedby="my-helper-text" />
      </FormControl>

    </Card>
  );
}
export default MessageSender;