
import { Box } from '@mui/material';
import React from 'react';
import { ConfigContext } from './App';
import styles from './GradientBG.module.css';
function GradientBG() {

    const config = React.useContext(ConfigContext);
    return (

        <>
            <svg height="0" width="0">
                <filter id='shadow' colorInterpolationFilters="sRGB">
                    <feDropShadow dx="2" dy="2" stdDeviation="3" floodOpacity="0.5" />
                </filter>
            </svg>
            <Box sx={{ position: 'absolute' }}>
                <svg>
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop stopColor={config.PrimaryColor} offset="0%" />
                            <stop stopColor={config.PrimaryColor} offset="100%" />
                        </linearGradient>
                    </defs>
                </svg>
                <svg>
                    <defs>
                        <linearGradient id="gradientActive" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop stopColor={config.PrimaryColor} offset="0%" />
                            <stop stopColor={config.PrimaryColor} offset="100%" />
                        </linearGradient>
                    </defs>
                </svg>
            </Box>
            <Box sx={{ position: 'absolute', display: 'flex', top: -35, right: -650, transform: 'rotate(180deg)' }} className={styles.waveWrapper}>

                <Box sx={{ position: 'absolute', bottom: 0 }} className={styles.wave}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={6400} height="125">

                        <defs>
                            <linearGradient id="a1" x1="50%" x2="50%" y1="-10.959%" y2="100%">
                                <stop stopColor={config.PrimaryColor} offset="0%" />
                                <stop stopColor={'white'} offset="100%" />
                            </linearGradient>
                        </defs>
                        <path filter="url(#shadow)" className={styles.bgPath} fill="url(#a1)" fillOpacity={"1"} fillRule="evenodd" d="M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z" transform="matrix(-1 0 0 1 1600 0)" />
                    </svg>
                </Box>

                <Box sx={{ position: 'absolute', bottom: 0 }} className={styles.wave}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={6400} height="115">
                        <defs>
                            <linearGradient id="a2" x1="50%" x2="50%" y1="-10.959%" y2="100%">
                                <stop stopColor={config.TertiaryColor} offset="0%" />
                                <stop stopColor={config.TertiaryColor} offset="100%" />
                            </linearGradient>
                        </defs>
                        <path className={styles.bgPath} fill="url(#a2)" fillOpacity={"1"} fillRule="evenodd" d="M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z" transform="matrix(-1 0 0 1 1600 0)" />
                    </svg>
                </Box>
            </Box>
            <Box sx={{ position: 'absolute', display: 'flex', bottom: 0, left: -650 }} className={styles.waveWrapper}>
                <Box sx={{ position: 'absolute', bottom: -35, boxShadow: 'none !important' }} className={styles.wave}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={6400} height="145">
                        <defs>
                            <linearGradient id="b1" x1="50%" x2="50%" y1="-10.959%" y2="100%">
                                <stop stopColor={config.PrimaryColor} offset="0%" />
                                <stop stopColor={'white'} offset="100%" />
                            </linearGradient>
                        </defs>
                        <path className={styles.bgPath} fill="url(#b1)" fillOpacity={"1"} fillRule="evenodd" d="M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z" transform="matrix(-1 0 0 1 1600 0)" />
                    </svg>
                </Box>
                <Box sx={{ position: 'absolute', bottom: -45 }} className={styles.wave}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={6400} height="135">
                        <defs>
                            <linearGradient id="b2" x1="50%" x2="50%" y1="-10.959%" y2="100%">
                                <stop stopColor={config.TertiaryColor} offset="0%" />
                                <stop stopColor={config.TertiaryColor} offset="100%" />
                            </linearGradient>
                        </defs>
                        <path className={styles.bgPath} fill="url(#b2)" fillOpacity={"1"} fillRule="evenodd" d="M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z" transform="matrix(-1 0 0 1 1600 0)" />
                    </svg>
                </Box>
            </Box>

        </>
    )
}

export default GradientBG