import { alpha, CardMedia } from "@mui/material";
import { useContext } from "react";
import { ConfigContext } from "../../App/App";
import styles from './HeroLogo.module.css';

function HeroLogo() {
    const config = useContext(ConfigContext);
    return (
        <CardMedia
            title="Logo"
            sx={{
                px: 3,
                py: 3,
                height: '100%',
                backgroundImage: `radial-gradient(${alpha(config.TertiaryColor, .7)} 0%,
             ${alpha(config.PrimaryColor, 1)}), url('${config.HeaderBackgroundImageHome}')`,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                overflow: 'hidden',
                borderRadius: `${config.BorderRadiusNumber}px`
            }}
        >
            <img className={styles.heroLogo} src={config.LogoUrl} alt="Logo" />
        </CardMedia>)
}

export default HeroLogo