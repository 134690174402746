import Header from '../Shared/Header/Header';
import WrapperBox from '../Shared/WrapperBox/WrapperBox';
import Body from './Body/Body';

function Search() {
    return (
        <WrapperBox>
            <Header pageName='Search' />
            <Body />
        </WrapperBox>
    );
}
export default Search; 