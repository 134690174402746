import styles from './Body.module.css';
import MessageSender from './MessageSender/MessageSender';
import Messages from './Messages/Messages';
function Body() {
  return (
    <div className={styles.chatBody} id="chatBody">
      <Messages></Messages>
      <MessageSender />
    </div>
  );
}
export default Body; 