import { Box, Button, Collapse, Typography } from '@mui/material'
import React, { useState } from 'react'
import ArrowDropDown from 'remixicon-react/ArrowDownSFillIcon'
import LinkIcon from 'remixicon-react/LinksFillIcon'
import EmailIcon from 'remixicon-react/MailFillIcon'
import MapIcon from 'remixicon-react/MapFillIcon'
import PhoneIcon from 'remixicon-react/SmartphoneFillIcon'
import { ContactInformation } from '../../../App/appReducer'
import ContactInfoListItem from './ContactInfoListItem'
import styles from './HeaderDepartmentModal.module.css'

function HeaderDepartmentModal(props: { actionsParams: ContactInformation, toggleDrawer: (event: React.KeyboardEvent | React.MouseEvent) => void }) {
  const [showDeptInfo, setShowDepartmentInfo] = useState(false);
  return (
    <Box
      className={styles.departmentDrawerWrapper}
      sx={{ width: 'auto', height: 'auto', p: 2 }}
      role="presentation"
    >
      <Typography sx={{ fontSize: '.9rem;', fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box component="span" sx={{ display: 'flex', alignItems: 'center' }} onClick={() => setShowDepartmentInfo(!showDeptInfo)} >
          Looking for {props.actionsParams.Title}? <ArrowDropDown />
        </Box>
        <a href={props.actionsParams.Url} target="_blank" rel='noreferrer'><Button variant='outlined' sx={{ py: .5, px: 1 }}>Visit</Button></a>
      </Typography>
      <Collapse in={showDeptInfo} sx={{
        overflowY: "hidden",
        maxHeight: '100%'
      }}>
        <div className={styles.departmentDrawerList}>
          <ContactInfoListItem
            icon={<span><PhoneIcon fontSize="small" color="primary" /></span>}
            text={props.actionsParams.Phone}
            type="phone"
          />
          <ContactInfoListItem
            icon={<span><EmailIcon fontSize="small" color="primary" /></span>}
            text={props.actionsParams.Email}
            type="email"
          />
          <ContactInfoListItem
            icon={<span><MapIcon fontSize="small" color="primary" /></span>}
            text={props.actionsParams.Address}
            type="address"
          />
          <ContactInfoListItem
            icon={<span><LinkIcon fontSize="small" color="primary" /></span>}
            text={props.actionsParams.Url}
            type="url"
          />
        </div>
      </Collapse>
    </Box>
  );
}
export default HeaderDepartmentModal;