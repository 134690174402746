import { Badge, BottomNavigation, BottomNavigationAction, Card, FilledInput, FormControl, InputAdornment, InputLabel, LinearProgress } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from 'remixicon-react/CloseFillIcon';
import HomeIcon from 'remixicon-react/HomeFillIcon';
import LinksIcon from 'remixicon-react/LinksFillIcon';
import ChatIcon from 'remixicon-react/QuestionAnswerFillIcon';
import SearchIcon from 'remixicon-react/Search2FillIcon';
import { useSearch } from '../../../../Utilities/Search';
import { ConfigContext } from '../../../App/App';
import { AppContext } from '../../../App/AppContext';
import { routes } from './../../../../routing';
import styles from './FooterActions.module.css';
import FooterNavigationAction from './FooterNavigationAction';
import RelatedLinks from './RelatedLinks';
import SearchResults from './SearchResults';
import StructuredDataFooterItems from './StructuredDataFooterItems';

export type RelatedLink = {
  url: string,
  title: string
}

function FooterActions() {
  const config = useContext(ConfigContext);
  const { state, dispatch } = useContext(AppContext);
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [showLinks, setShowLinks] = useState(false);
  const [showData, setShowData] = useState(false);
  const search = useSearch();

  const navigateToRoute = (route: string) => {

    setShowLinks(false)
    setShowData(false)
    navigate(route)
  }

  const changeNavState = (newValue: number) => {
    if (newValue === 3) {
      if (showLinks)
        dispatch({ type: "setShowSearchResults", showSearchResults: false })
      setShowLinks(!showLinks)
      setShowData(!showData)
      dispatch({ type: "setRelatedLinksViewed", viewed: true });
    }
    else {
      setValue(newValue);
      dispatch({ type: "setShowSearchLinks", showSearchLinks: false })
    }
  }

  const showSearchInFooter = () => {
    dispatch({ type: "setShowSearch", showSearch: true })
    dispatch({ type: "setShowSearchLinks", showSearchLinks: false })
  }
  const showFilteredDataInFooter = () => {
    setShowData(!showData)
  }
  const hideSearchInFooter = () => {
    dispatch({ type: "setShowSearch", showSearch: false })
    dispatch({ type: "setShowSearchResults", showSearchResults: false })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "setSearchQuery", searchQuery: event.target.value })
  };

  const onKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && state.searchResults.searchQuery.length > 0 && state.searchResults.searchQuery.trim() !== "") {
      const { target } = event;
      (target as HTMLInputElement).blur();
      await search();
    }
  }

  return (
    <>
      <Card className={styles.footerActions} sx={{ borderTop: `2px solid ${config.TertiaryColor}`, borderBottom: `0px`, borderRight: `2px solid ${config.TertiaryColor}`, borderLeft: `2px solid ${config.TertiaryColor}`, borderRadius: (state.searchResults.showSearchResults || showLinks || showData || (state.searchResults.showSearch && (state.searchResults.searchResults === undefined || state.searchResults.searchResults.length === 0)) ? `${config.BorderRadiusNumber}px ${config.BorderRadiusNumber}px 0px 0px !important` : `${config.BorderRadiusNumber}px !important`), boxShadow: config.BoxShadow, overflow: 'hidden' }} >
        {state.searchResults.showSearch && <FormControl variant="filled" sx={{ background: 'none' }} >
          <InputLabel htmlFor="my-input">Start a Search</InputLabel>
          <FilledInput id="my-input" aria-describedby="my-helper-text" disableUnderline endAdornment={
            <InputAdornment position="end">
              <CloseIcon onClick={hideSearchInFooter} />
            </InputAdornment>
          } fullWidth autoFocus={(state.searchResults.searchResults === undefined || state.searchResults.searchResults.length === 0)} sx={{ background: 'none', boxShadow: 'none', borderRadius: '0px !Important' }}
            disabled={state.searchResults.searching}
            onKeyUp={onKeyUp}
            value={state.searchResults.searchQuery}
            onChange={handleChange}
          />
          {state.searchResults.searching &&
            <LinearProgress color="secondary" />}
        </FormControl>}
        {!state.searchResults.showSearch &&
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              event.preventDefault();
              changeNavState(newValue);
            }}
            sx={{ py: 1, px: 2, height: 'auto', minHeight: '50px', background: 'white' }}
          >
            <FooterNavigationAction
              route={routes.home}
              label='Home'
              navigate={navigateToRoute}
              icon={<HomeIcon fontSize='large' />}
              flipColors={false}
            ></FooterNavigationAction>
            <FooterNavigationAction
              route={routes.chat}
              label='Chat'
              navigate={navigateToRoute}
              icon={<ChatIcon fontSize='large' />}
              flipColors={true}
            ></FooterNavigationAction>
            <FooterNavigationAction
              route={routes.search}
              label='Search'
              navigate={showSearchInFooter}
              icon={<SearchIcon fontSize='large' />}
              flipColors={true}
            ></FooterNavigationAction>
            {state.relatedLinks.relatedLinks?.length > 0 &&
              <BottomNavigationAction label="Related Links" icon={
                <Badge color="error" variant="dot" invisible={!state.relatedLinks.newLinks} >
                  <LinksIcon color="action" fontSize='large' />
                </Badge>
              } className={styles.footerActionBtn} sx={{
                borderRadius: `${config.BorderRadiusNumber}px`,
                "& svg, .MuiBottomNavigationAction-label": {
                  color: (state.searchResults.showLinks ? "primary.main" : "default"),
                  fontWeight: (state.searchResults.showLinks ? "bold" : "normal"),
                },
                "& svg path": {
                  fill: (state.searchResults.showLinks ? "url(#gradientActive) !important" : "url(#gradient)"),
                },
                "& span": {
                  whiteSpace: 'nowrap'
                }
              }} />}
            {state.filteredFormData?.length > 0 &&
              <BottomNavigationAction onClick={showFilteredDataInFooter} label="Programs" icon={
                <Badge color="error" variant="dot" invisible={!state.filteredFormData} >
                  <LinksIcon color="action" fontSize='large' />
                </Badge>
              } className={styles.footerActionBtn} sx={{
                borderRadius: `${config.BorderRadiusNumber}px`,
                "& svg, .MuiBottomNavigationAction-label": {
                  color: (state.searchResults.showLinks ? "primary.main" : "default"),
                  fontWeight: (state.searchResults.showLinks ? "bold" : "normal"),
                },
                "& svg path": {
                  fill: (state.searchResults.showLinks ? "url(#gradientActive) !important" : "url(#gradient)"),
                },
                "& span": {
                  whiteSpace: 'nowrap'
                }
              }} />}
          </BottomNavigation>}
      </Card>
      <RelatedLinks showLinks={showLinks} />
      <StructuredDataFooterItems showDataItems={showData} />
      <SearchResults showSearchResults={state.searchResults.showSearchResults || (state.searchResults.showSearch && state.searchResults.searchResults !== undefined && state.searchResults.searchResults.length > 0)} />
    </>
  );
}
export default FooterActions;