import { Box, Card, Fade, FilledInput, FormControl, InputAdornment, InputLabel, ListItem, ListItemText } from "@mui/material";
import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import CloseIcon from 'remixicon-react/CloseFillIcon';
import SendIcon from 'remixicon-react/SendPlane2FillIcon';
import { ConfigContext } from "../../App/App";

export type ActionButtonProps = {
    icon: ReactNode;
    primaryText: string;
    secondaryText: string;
    onActionEnter: (inputText: string) => void
}

function ActionButton(props: { actionButtonProps: ActionButtonProps }) {

    const [showInput, setShowInput] = useState(false)
    const inputRef = useRef<HTMLElement>();
    const config = useContext(ConfigContext);
    const [inputMessage, setInputMessage] = useState('');

    const toggleShowInput = () => {
        setShowInput(!showInput)
    }

    useEffect(() => {
        if (showInput) {
            if (inputRef?.current) {
                inputRef.current.focus()
            }
        }
    }, [showInput])


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputMessage(event.target.value);
    };

    const performAction = () => {
        let newInput = inputMessage
        props.actionButtonProps.onActionEnter(newInput);
        setInputMessage("");
        toggleShowInput();
    }

    const onKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            const { target } = event;
            (target as HTMLInputElement).blur();
            performAction()
        }
    }


    return (
        <Card onClick={() => toggleShowInput()} sx={{ cursor: 'pointer', height: '75px', display: 'flex', alignItems: 'center', background: config.TextInputBackground }}>
            <ListItem sx={{ py: 0, height: '100%' }}>
                <Box sx={{ mr: 2 }}>
                    {!showInput &&
                        <span>{props.actionButtonProps.icon}</span>
                    }
                    {showInput &&
                        <span><CloseIcon color="primary" /></span>

                    }
                </Box>
                {!showInput &&
                    <Fade in={true}>
                        <ListItemText
                            primary={props.actionButtonProps.primaryText}
                            secondary={props.actionButtonProps.secondaryText ? props.actionButtonProps.secondaryText : null}
                        />
                    </Fade>
                }
                {showInput &&
                    <FormControl variant="filled" fullWidth sx={{ height: '100%' }}>
                        <InputLabel htmlFor="my-input">{props.actionButtonProps.primaryText}</InputLabel>
                        <FilledInput sx={{ height: '100%', boxShadow: 'none' }} id="my-input" aria-describedby="my-helper-text" disableUnderline inputRef={inputRef}
                            endAdornment={
                                <InputAdornment position="end" >
                                    <SendIcon onClick={performAction} size={16} />
                                </InputAdornment>
                            }
                            onKeyUp={onKeyUp}
                            value={inputMessage}
                            onChange={handleChange} />
                    </FormControl>
                }
            </ListItem>
        </Card >
    );
}
export default ActionButton; 